import { createSelector } from "reselect";
import { evaluateFormularstatus } from "../../models/formularstatus";
import { zeroPad } from "../utils/period";
import { MONTH_VIEW, MULTIPLE_ARTIST_VIEW, SINGLE_ARTIST_VIEW } from "./CalendarReducer";

export const isMultipleArtistView = viewType => viewType === MULTIPLE_ARTIST_VIEW
export const isSingleArtistView = viewType => viewType === SINGLE_ARTIST_VIEW
export const isMonthView = viewType => viewType === MONTH_VIEW

export const selectCalendarState = createSelector(
    [(state) => state]
    , (state) => state.calendar ?? {}
)


export const selectCalendarEvents = createSelector([selectCalendarState], (state) => state?.events)
export const selectCalendarSelectedSearchedKunden = createSelector([selectCalendarState], (state) => state?.selectedSearchedKunde)
export const selectCalendarSearchedKunden = createSelector([selectCalendarState], (state) => state?.searchedKunden)
export const selectIsSearchedKundenResultAvailable = createSelector([selectCalendarState], (state) => Boolean(state.searchedKunden))
export const selectCalendarKundenMap = createSelector([selectCalendarState], (state) => state?.kunden)
export const selectViewConfigurationType = createSelector([selectCalendarState], (state) => state?.viewType)
export const selectSelectedTerminIDCalendar = createSelector([selectCalendarState], (state) => state?.selectedTerminID)
export const selectSelectedLocationKeysCalendar = createSelector([selectCalendarState], (state) => state?.selectedLocationKeys)
export const selectSelectedKundIDCalendar = createSelector([selectCalendarState], (state) => state?.selectedKundID)
export const selectSelectedKundeCalendar = createSelector(
    [selectCalendarKundenMap
    , selectSelectedKundIDCalendar]
    , (kundenMap, selectedKundID) => kundenMap && selectedKundID && kundenMap[selectedKundID]
)
export const selectCalendarSlotDuration = createSelector(
    [selectCalendarState]
    , state => '00:' + zeroPad(state.slotDuration || 30, 2)
)
export const selectCalendarEventParams = createSelector([selectCalendarState], state => state?.eventsParams)

export const selectCalendarEventZeitraum = createSelector([selectCalendarEventParams], params => params?.zeitraum)

export const selectTermine = createSelector([selectCalendarEvents], events => events?.termine)

export const selectLoadedZeitraumOfEvents = createSelector([selectCalendarEvents], events => events?.loadedZeitraum)
export const selectHolidays = createSelector([selectCalendarEvents], events => events?.holidays)

export const selectIsTermineLoading = createSelector([selectCalendarState], state => Boolean(state?.eventsLoading))
export const selectSelectedCalendarArtistID = createSelector([selectCalendarState], state => state?.calendarArtist)
export const selectCalendarKundenTermine = createSelector([selectCalendarState], state => state?.selectedKundenTermine)
export const selectCalendarSelectedTerminToaster = createSelector([selectCalendarState], state => state?.selectedTerminToaster)
export const selectCalendarSelectedTerminToasterImages = createSelector([selectCalendarState], state => state?.toasterImages)
export const isSelectCalendarSelectedTerminToasterImagesLoading = createSelector([selectCalendarState], state => Boolean(state?.toasterImagesLoading))

export const selectCalendarSelectedTerminToasterProjekt = createSelector([selectCalendarSelectedTerminToaster], state => state?.projekt)
export const selectCalendarSelectedTerminToasterAnamneseFile = createSelector([selectCalendarSelectedTerminToaster], state => state?.anamnese)
export const selectCalendarSelectedTerminToasterTermin = createSelector([selectCalendarSelectedTerminToaster], state => state?.termin)
export const selectCalendarSelectedTerminToasterFormularStatus = createSelector(
    [selectCalendarSelectedTerminToaster]
    , t => t && t.formularStatus && evaluateFormularstatus(t.formularStatus)
)

export const selectSearchTimeslotLoading = createSelector([selectCalendarState], (state) => state?.searchTimeslotsLoading)
export const selectFoundTimeslot = createSelector([selectCalendarState], (state) => state?.timeslots)

