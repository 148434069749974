import i18next from "i18next";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";
import { deletePad, getNewPadSecret, getPads, insertPad, updateDocuVersandArtist } from "../../../api/studioApi";
import { getKissSignTranslation } from "../../../components/settings/helper";
import { i18_KISSSIGN, i18_TRANSLATION } from "../../../i18nReferences";
import { DELETION_FAILED, INFO_DELETED_SUCCESSFULLY, INFO_SAVED_SUCCESSFULLY, SAVING_FAILED, SHOW_SAVED_SUCCESSFULLY, createErrorNotification, createInfoNotification } from "../../../models/notification";
import { removeExpiredSecrets } from "../../../models/pads";
import { getArtistToken } from "../../auth/token.saga";
import { reloadCommonStudioAction } from "../../common/commonActions";
import { emitNotificationAction } from "../../notification/notificationAction";
import { emitChangedNotification } from "../../notification/notificationSaga";
import { extractDataFromResponse } from "../../saga-utils/httpInterceptorWrapper";
import {
    CHANGE_DOKU_VERSAND_ARTIST,
    DELETE_PAD,
    INSERT_PAD,
    LOAD_PADS,
    SET_NEW_PAD_SECRET,
    loadPadsAction,
    loadPadsSuccessAction
} from "./kissSignActions";
import { selectPads } from "./kissSignSelector";

const getKissSignNotificationCategory = (t) => t("CALENDAR_MENU.KISSSIGN_SETTINGS_MENU_TEXT", { ns: i18_TRANSLATION });

function* processLoadPads() {
    try {
        const token = yield getArtistToken();
        if (token) {
            let pads = extractDataFromResponse(yield call(getPads, { token }));
            if (pads.length) {
                pads = pads.map((p) => removeExpiredSecrets(p));
            }
            yield put(loadPadsSuccessAction(pads));
        }
    } catch (err) {
        console.log(err);
    }
}

function* watchLoadPads() {
    yield takeLatest(LOAD_PADS, processLoadPads);
}

function* processSetNewPadSecret({ payload }) {
    const category = getKissSignNotificationCategory(i18next.t);
    try {
        const token = yield getArtistToken();
        if (token) {
            yield call(getNewPadSecret, { token, padId: payload.pad.PadID });
            yield put(
                emitNotificationAction(
                    createInfoNotification(
                        i18next.t("PAD_NOTIFICATION.NEW_SECRET_SUCCESS", { ns: i18_KISSSIGN, Name: payload.pad.Name }),
                        category
                    )
                )
            );
            yield put(loadPadsAction());
        }
    } catch (err) {
        yield put(
            emitNotificationAction(
                createErrorNotification(
                    i18next.t("PAD_NOTIFICATION.NEW_SECRET_FAILURE", { ns: i18_KISSSIGN, Name: payload.pad.Name }),
                    category
                )
            )
        );
        console.log(err);
    }
}

function* watchSetNewPadSecret() {
    yield takeLatest(SET_NEW_PAD_SECRET, processSetNewPadSecret);
}

function* processDeletePad({ payload }) {
    const { padId } = payload;
    const pads = yield select((state) => selectPads(state));
    const name = pads.find((p) => p.PadID === padId)?.Name;
    const category = getKissSignNotificationCategory(i18next.t);
    const data = name;
    try {
        const token = yield getArtistToken();

        if (token) {
            yield call(deletePad, { token, padId: padId });
            yield* emitChangedNotification({
                type: INFO_DELETED_SUCCESSFULLY,
                data,
                category,
            });
            yield put(loadPadsAction());
        }
    } catch (err) {
        yield* emitChangedNotification({
            type: DELETION_FAILED,
            data,
            category,
        });
        console.log(err);
    }
}

function* watchDeletePad() {
    yield takeLatest(DELETE_PAD, processDeletePad);
}

function* processInsertPad({ payload }) {
    const { pad } = payload;
    const category = getKissSignNotificationCategory(i18next.t);
    const data = pad.Name;
    try {
        const token = yield getArtistToken();

        if (token) {
            yield call(insertPad, { token, pad });
            yield* emitChangedNotification({
                type: INFO_SAVED_SUCCESSFULLY,
                data,
                category,
            });
            yield put(loadPadsAction());
        }
    } catch (err) {
        yield* emitChangedNotification({
            type: SAVING_FAILED,
            data,
            category,
        });
        console.log(err);
    }
}

function* watchInserPad() {
    yield takeLatest(INSERT_PAD, processInsertPad);
}

function* processChangeDokuVersandArtistAction({ payload }) {
    const { artistId } = payload;
    const category = getKissSignNotificationCategory(i18next.t);
    const data = getKissSignTranslation(i18next.t, "AUTO_DELIVERY_HEADER");
    try {
        const token = yield getArtistToken();
        if (token) {
            yield call(updateDocuVersandArtist, { token, artistId });
            yield put(reloadCommonStudioAction());
            yield* emitChangedNotification({
                type: SHOW_SAVED_SUCCESSFULLY,
                data,
                category,
            });
        }
    } catch (err) {
        yield* emitChangedNotification({
            type: SAVING_FAILED,
            data,
            category,
        });
        console.log(err);
    }
}

function* watchChangeDokuVersandArtistAction() {
    yield takeLatest(CHANGE_DOKU_VERSAND_ARTIST, processChangeDokuVersandArtistAction);
}

const kissSignSaga = [
    fork(watchLoadPads),
    fork(watchSetNewPadSecret),
    fork(watchDeletePad),
    fork(watchInserPad),
    fork(watchChangeDokuVersandArtistAction),
];

export default kissSignSaga;
