import { LOGOUT_ARTIST, LOGOUT_STUDIO } from "../auth/authActions";
import { LOAD_PADS_SUCCESS } from "../settings/kiss-sign/kissSignActions";
import { pick } from "../utils/lodash-replacement";
import {
    CLEAR_STUDIO_CHANGED,
    CLEAR_SUPPORT_TICKET,
    CREATE_SUPPORT_TICKET,
    CREATE_SUPPORT_TICKET_FAILURE,
    CREATE_SUPPORT_TICKET_SUCCESS,
    DISABLE_ARTIST_INFO,
    LOAD_COMMON,
    LOAD_COMMON_FAILURE,
    LOAD_COMMON_SUCCESS,
    LOAD_LOGGED_ARTIST_SETTINGS_SUCCESS,
    RELOAD_COMMON_ARTISTS,
    RELOAD_COMMON_ARTISTS_FAILED,
    RELOAD_COMMON_ARTISTS_SUCCESS,
    RELOAD_COMMON_MSG_VORLAGEN,
    RELOAD_COMMON_MSG_VORLAGEN_FAILED,
    RELOAD_COMMON_MSG_VORLAGEN_SUCCESS,
    RELOAD_COMMON_STUDIO_FAILURE,
    RELOAD_COMMON_STUDIO_SUCCESS,
    STUDIO_CHANGED
} from "./commonActions";

const INITIAL = {
    commonLoading: true
};

export const SUPPORT_TICKET_SENDING = 'sending'
export const SUPPORT_TICKET_FAILURE = 'failure'
export const SUPPORT_TICKET_SUCCESS = 'success'

export default function commonReducer(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_COMMON:
        case RELOAD_COMMON_ARTISTS:
        case RELOAD_COMMON_MSG_VORLAGEN:
            return {
                ...state,
                commonLoading: true
            }
        case LOAD_COMMON_SUCCESS:
            return {
                ...state,
                commonLoading: false,
                ...action.payload.common
            }
        case RELOAD_COMMON_ARTISTS_SUCCESS:
        case RELOAD_COMMON_MSG_VORLAGEN_SUCCESS:
        case RELOAD_COMMON_STUDIO_SUCCESS:
            return {
                ...state,
                commonLoading: false,
                ...action.payload
            }

        case LOAD_COMMON_FAILURE:
            return {
                ...state,
                commonLoading: false,
                studioNames: undefined
            }
        case RELOAD_COMMON_ARTISTS_FAILED:
        case RELOAD_COMMON_MSG_VORLAGEN_FAILED:
        case RELOAD_COMMON_STUDIO_FAILURE:
            return {
                ...state,
                commonLoading: false,
            }

        case DISABLE_ARTIST_INFO:
            return {
                ...state,
                disabledInfo: state.disabledInfo ? {
                    ...state.disabledInfo,
                    [action.payload.infoType]: true
                } : {[action.payload.infoType]: true}
            }

        case LOGOUT_ARTIST:
        case LOGOUT_STUDIO:
            return {
                ...state,
                disabledInfo: undefined
            }
        case LOAD_LOGGED_ARTIST_SETTINGS_SUCCESS:
            return {
                ...state,
                disabledInfo: action.payload.artistSettings?.disabledInfo,
            }

        case LOAD_PADS_SUCCESS:
            return {
                ...state,
                studio: state.studio ? {
                    ...state.studio,
                    pads: state.studio.pads ? action.payload.pads.map(p => pick(p, ['PadID', 'Name', 'Type', 'Ppi'])) : undefined
                } : undefined
            }

        case STUDIO_CHANGED:
            return {
                ...state,
                studioChanged: true
            }
        case CLEAR_STUDIO_CHANGED:
            return {
                ...state,
                studioChanged: false
            }
        case CLEAR_SUPPORT_TICKET:
            return {
                ...state,
                supportTicketStatus: undefined
            }
        case CREATE_SUPPORT_TICKET:
            return {
                ...state,
                supportTicketStatus: 'sending'
            }
        case CREATE_SUPPORT_TICKET_SUCCESS:
            return {
                ...state,
                supportTicketStatus: 'success'
            }
        case CREATE_SUPPORT_TICKET_FAILURE:
            return {
                ...state,
                supportTicketStatus: 'failure'
            }

        default:
            return state;
    }
}
