import { createSelector } from "reselect";

export const selectNachrichtState = createSelector(
    [(state) => state]
    , (state) => state.nachricht
)

export const selectNachrichtKundeData = createSelector([selectNachrichtState], state => state?.kundeData)
export const selectNachrichtKundenProjekte = createSelector([selectNachrichtKundeData], state => state?.projekte ?? [])

export const selectNachrichtTerminData = createSelector([selectNachrichtState], state => state?.terminData)

export const selectNachrichtKunde = createSelector(
    [selectNachrichtKundeData
    , selectNachrichtTerminData]
    , (kundeData, terminData) => kundeData ? kundeData.kunde : terminData?.kunde
)

export const selectNachrichtTermin = createSelector([selectNachrichtTerminData], state => state?.termin)
export const selectNachrichtProjekt = createSelector([selectNachrichtTerminData], state => state?.projekt)

export const selectIsSending = createSelector([selectNachrichtState], state => Boolean(state?.sending))