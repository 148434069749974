import {i18_TEMPLATES} from "../i18nReferences";
import * as yup from "yup";

export const MSG_VORLAGE_TYP_AUTO_REMINDER = 2
export const MSG_VORLAGE_TYP_AUTO_GEBURTSTAG = 1

export const getMessageTemplateColumns = (t, translatedMsgVorlagenAtomationListTextMap) => [
    {field: 'Bezeichnung', headerName: t('MSG_VORLAGE.BEZEICHNUNG_LABEL', {ns: i18_TEMPLATES}), flex: 1},
    {field: 'Reminder', headerName: t('MSG_VORLAGE.REMINDER_LABEL', {ns: i18_TEMPLATES}), type: 'boolean'},
    {
        field: 'MsgTyp',
        headerName: t('MSG_VORLAGE_TABLE.AUTO_VORLAGE_HEADER', {ns: i18_TEMPLATES}),
        minWidth: 150,
        valueGetter: (value) => value && translatedMsgVorlagenAtomationListTextMap[value]
    },
]

export const createMsgVorlageSchema = () => {
    return yup.object().shape({
        Betreff: yup.string().required().min(3).max(150),
        Bezeichnung: yup.string().required().min(3).max(200),
        TextEmail: yup.string().required().min(3).max(1000),
        TextSMS: yup.string().required().min(3).max(1000)
    })
}

export const getTranslatedMsgVorlagenAtomationInfoTextMap = (t) => ({
    [MSG_VORLAGE_TYP_AUTO_REMINDER]: t('MSG_VORLAGE.AUTO_REMINDER_INFO_TEXT', {ns: i18_TEMPLATES}),
    [MSG_VORLAGE_TYP_AUTO_GEBURTSTAG]: t('MSG_VORLAGE.AUTO_GEBURTSTAG_INFO_TEXT', {ns: i18_TEMPLATES})
})

export const getTranslatedMsgVorlagenAtomationListTextMap = (t) => ({
    [MSG_VORLAGE_TYP_AUTO_REMINDER]: t('MSG_VORLAGE_TABLE.AUTO_VORLAGE_REMINDER_TEXT', {ns: i18_TEMPLATES}),
    [MSG_VORLAGE_TYP_AUTO_GEBURTSTAG]: t('MSG_VORLAGE_TABLE.AUTO_VORLAGE_BIRTHDAY_TEXT', {ns: i18_TEMPLATES})
})

export const sortByBezeichnung = (v1, v2) => v1.Bezeichnung?.toUpperCase() > v2.Bezeichnung?.toUpperCase() ? 1 : -1
export const convertMsgVorlagenMapToList = map => map && Object.values(map).sort(sortByBezeichnung) || []
export const STANDARD_AUTO_MESSAGE_SMS_SEND_TIME = [10, 12, 14, 16]
