import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import i18n from "i18next";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { selectLocale, selectLoggedInArtistID } from "../store/auth/authSelector";
import { loadCommonAction } from "../store/common/commonActions";
import { selectHistory } from "../store/router/routerSelectors";
import LoginArtistContainer from "./auth/LoginArtistContainer";
import LoginStudioContainer from "./auth/LoginStudioContainer";
import BackendErrorContainer from "./error/BackendErrorContainer";

import { createTheme, CssBaseline, responsiveFontSizes, ThemeProvider } from "@mui/material";
import { loadArtistSettings, saveArtistThemeSetting } from "../api/localArtistPersistence";
import { initializeMuiLicense } from "../config/muiLicense";
import { useEventLogger } from "../hooks/useEventLogger";
import { clearActiveNotificationAction } from "../store/notification/notificationAction";
import { selectAllButInfoNotification } from "../store/notification/notificationSelector";
import { clearHistory } from "../store/router/routerActions";
import ColorModeContext from "./common/ColorModeContext";
import {
    AUTO_MESSAGE_SETTINGS_SUBPATH,
    CUSTOMER_PORTAL_BASEPATH,
    FORMS_EDITOR_PATH,
    FORMS_TEMPLATE_SUBPATH,
    KUNDEN_IMPORT,
    MESSAGES_SUBPATH,
    KISSSIGN_AUTO_DELIVERY_SUBPATH,
    KISSSIGN_BASEPATH,
    SEND_MESSAGE_PATH,
    STUDIO_APPOINTMENT_TYPES_SUBPATH,
    STUDIO_BASE_SUBPATH,
    STUDIO_BASEPATH,
    STUDIO_GGL_SYNC_SUBPATH,
    STUDIO_INVOICES_SUBPATH,
    STUDIO_LOCATIONS_SUBPATH,
    STUDIO_SUBSCRIPTION_SUBPATH,
    STUDIO_V2_API_SUBPATH,
    TEMPLATE_BASE_PATH,
    TERMIN_ICALIMPORT,
    VOUCHERS_SUBPATH,
} from "./componentPaths";
import NeuKundeDoublettenCheckContainer from "./kunde/doubletten/NeuKundeDoublettenCheckContainer";
import SessionNotification from "./notification/SessionNotification";
import TimelineContainer from "./timeline/TimelineContainer";
import { useBackendVersionSync } from "./useBackendVersionSync";

const ICalImportContainer = React.lazy(() => import("./termin/import/ICalImportContainer"));
const CalendarContainer = React.lazy(() => import("./calendar/CalendarContainer"));
const EditTerminContainer = React.lazy(() => import("./termin/EditTerminContainer"));
const KundeEditContainer = React.lazy(() => import("./kunde/KundeEditContainer"));
const SendNachrichtContainer = React.lazy(() => import("./nachricht/send/SendNachrichtContainer"));
const PadContainer = React.lazy(() => import("./settings/pads/PadContainer"));
const KundeSearchContainer = React.lazy(() => import("./calendar/kunden/mobile/KundenSuchContainer"));
const KundenTerminListContainer = React.lazy(() => import("./kunde/mobile/KundenTerminListContainer"));
const NewFinalizerContainer = React.lazy(() => import("./finalizer/FinalizerContainer"));
const ArtistEditBaseContainer = React.lazy(() => import("./artist/base/ArtistEditBaseContainer"));
const ArtistEditMailContainer = React.lazy(() => import("./artist/mail/ArtistEditMailContainer"));
const ArtistEditBusinessHoursContainer = React.lazy(() => import("./artist/hours/ArtistEditBusinessHoursContainer"));
const ArtistEditLocationContainer = React.lazy(() => import("./artist/locations/ArtistEditLocationContainer"));
const ArtistEditRightsContainer = React.lazy(() => import("./artist/rights/ArtistEditRightsContainer"));
const ArtistEditAssistContainer = React.lazy(() => import("./artist/assistant/ArtistEditAssistContainer"));
const KundenListContainer = React.lazy(() => import("./lists/kunden/KundenListContainer"));
const ProjektListContainer = React.lazy(() => import("./lists/projekte/ProjektListContainer"));
const TerminListContainer = React.lazy(() => import("./lists/termine/TerminListContainer"));
const ListEinnahmenContainer = React.lazy(() => import("./lists/einnahmen/ListEinnahmenContainer"));
const JobsListContainer = React.lazy(() => import("./lists/jobs/JobsListContainer"));
const ListContainer = React.lazy(() => import("./lists/lists/ListContainer"));
const CheckoutListContainer = React.lazy(() => import("./lists/checkouts/CheckoutListContainer"));
const VoucherOutListContainer = React.lazy(() => import("./lists/vouchers/VoucherOutListContainer"));
const VouchInListContainer = React.lazy(() => import("./lists/vouchers/VouchInListContainer"));
const DblExclusionsListContainer = React.lazy(() => import("./lists/kunden/DblExclusionsListContainer"));
const NachrichtListContainer = React.lazy(() => import("./lists/nachrichten/NachrichtListContainer"));
const KundenDoublettenListContainer = React.lazy(() => import("./lists/kunden/KundenDoublettenListContainer"));
const ArtistListContainer = React.lazy(() => import("./artist/list/ArtistListContainer"));
const ArtistHomeContainer = React.lazy(() => import("./artist/home/ArtistHomeContainer"));
const VorlagenHomeContainer = React.lazy(() => import("./templates/TemplatesHomeContainer"));
const MessageTemplateListContainer = React.lazy(() => import("./templates/messages/MessageTemplateListContainer"));
const MessageTemplateEditContainer = React.lazy(() => import("./templates/messages/MessageTemplateEditContainer"));
const AutoMessageSettingsContainer = React.lazy(() => import("./templates/auto-message/AutoMessageSettingsContainer"));
const KissSignHomeContainer = React.lazy(() => import("./settings/kissSign/KissSignHomeContainer"));
const DokuVersandContainer = React.lazy(() => import("./settings/kissSign/DokuVersandContainer"));
const StudioHomeContainer = React.lazy(() => import("./settings/studio/StudioHomeContainer"));
const EditStudioBaseContainer = React.lazy(() => import("./settings/studio/base/EditStudioBaseContainer"));
const EditStudioAboContainer = React.lazy(() =>
    import("./settings/studio/subscription/EditStudioSubscriptionContainer")
);
const LocationListContainer = React.lazy(() => import("./settings/studio/locations/LocationListContainer"));
const KundenImportContainer = React.lazy(() => import("./kunde/import/KundenImportContainer"));
const CustomerPortalContainer = React.lazy(() => import("./settings/customer-portal/CustomerPortalContainer"));
const GoogleSyncStudioSetting = React.lazy(() => import("./settings/studio/google-sync/GoogleSyncStudioSetting"));
const AppointmentTypesContainer = React.lazy(() =>
    import("./settings/studio/appointment-types/AppointmentTypesContainer")
);
const V2ApiSettings = React.lazy(() => import("./settings/studio/api/V2ApiStudioSettings"));
const SwaggerUI = React.lazy(() => import("./swagger/SwaggerUI"));
const FormsListContainer = React.lazy(() => import("./templates/forms/list/FormsListContainer"));
const FormEditContainer = React.lazy(() => import("./templates/forms/editor/FormEditContainer"));
const VoucherTemplateListContainer = React.lazy(() => import("./templates/vouchers/VoucherTemplateListContainer"));
const StudioInvoiceListContainer = React.lazy(() => import("./settings/studio/invoices/StudioInvoiceListContainer"));

const localeMap = {
    en: enLocale,
    de: deLocale,
};

const App = () => {
    const [mode, setMode] = React.useState("light");
    const { capturePathEvents } = useEventLogger();
    const history = useSelector((state) => selectHistory(state));
    const location = useLocation();
    const locale = useSelector((state) => selectLocale(state));
    const loggedInArtistID = useSelector((state) => selectLoggedInArtistID(state));
    const activeNotification = useSelector((state) => selectAllButInfoNotification(state));
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useBackendVersionSync();

    const colorMode = React.useMemo(
        () => ({
            saveColorMode: () => {
                if (loggedInArtistID) {
                    saveArtistThemeSetting(loggedInArtistID, { mode });
                }
            },
            toggleColorMode: () => {
                setMode((prevMode) => {
                    const newMode = prevMode === "light" ? "dark" : "light";
                    if (loggedInArtistID) {
                        saveArtistThemeSetting(loggedInArtistID, { mode: newMode });
                    }
                    return newMode;
                });
            },
            setColorMode: (mode) => {
                setMode(mode);
                if (loggedInArtistID) {
                    saveArtistThemeSetting(loggedInArtistID, { mode });
                }
            },
        }),
        [loggedInArtistID, mode]
    );

    useEffect(() => {
        initializeMuiLicense();
    }, []);

    useEffect(() => {
        if (location) {
            capturePathEvents(location.pathname);
        }
    }, [capturePathEvents, location]);

    useEffect(() => {
        if (loggedInArtistID) {
            const { themeSettings } = loadArtistSettings(loggedInArtistID);
            if (!themeSettings) {
                saveArtistThemeSetting(loggedInArtistID, { mode });
            } else {
                const newMode = themeSettings.mode || "light";
                setMode(newMode);
                saveArtistThemeSetting(loggedInArtistID, { mode: newMode });
            }
        }
    }, [loggedInArtistID, mode]);

    const theme = React.useMemo(() => {
        let newTheme = createTheme({
            palette: {
                mode,
            },
            components: {
                MuiInputBase: {
                    styleOverrides: {
                        input: {
                            // Überschreiben für Input-Felder vom Typ number:
                            "&[type=number]": {
                                MozAppearance: "textfield", // Firefox
                                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                                    WebkitAppearance: "none", // Chrome, Safari, Edge, Opera
                                    margin: 0,
                                },
                            },
                        },
                    },
                },
            },
        });
        return responsiveFontSizes(newTheme);
    }, [mode]);

    useEffect(() => {
        if (history) {
            navigate(history);
            dispatch(clearHistory());
        }
    }, [history, dispatch, navigate]);

    useEffect(() => {
        dispatch(loadCommonAction());
    }, [dispatch]);

    useEffect(() => {
        i18n.changeLanguage(locale);
    }, [locale]);

    const handleCloseNotification = () => dispatch(clearActiveNotificationAction());

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={localeMap[locale]}>
            <ColorModeContext.Provider value={colorMode}>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <React.Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route path="/" exact element={<CalendarContainer />} />
                            <Route path="/termin/neu" exact element={<EditTerminContainer neu />} />
                            <Route path={TERMIN_ICALIMPORT} exact element={<ICalImportContainer />} />
                            <Route path="/termin/:terminId" exact element={<EditTerminContainer />} />
                            <Route path="/finalizer" exact element={<NewFinalizerContainer />} />
                            <Route path="/login-studio" exact element={<LoginStudioContainer />} />
                            <Route path={"/login-artist"} exact element={<LoginArtistContainer />} />
                            <Route path={"/kunde/neu"} exact element={<NeuKundeDoublettenCheckContainer />} />
                            <Route path={"/kunde/suche"} exact element={<KundeSearchContainer />} />
                            <Route path={KUNDEN_IMPORT} exact element={<KundenImportContainer />} />
                            <Route path={"/kunde/:kundid/termine"} exact element={<KundenTerminListContainer />} />
                            <Route path={"/kunde/:kundid"} exact element={<KundeEditContainer />} />
                            <Route path={"/artist/:artistid/base"} exact element={<ArtistEditBaseContainer />} />
                            <Route path={"/artist/:artistid/mail"} exact element={<ArtistEditMailContainer />} />
                            <Route
                                path={"/artist/:artistid/hours"}
                                exact
                                element={<ArtistEditBusinessHoursContainer />}
                            />
                            <Route
                                path={"/artist/:artistid/locations"}
                                exact
                                element={<ArtistEditLocationContainer />}
                            />
                            <Route path={"/artist/:artistid/rights"} exact element={<ArtistEditRightsContainer />} />
                            <Route path={"/artist/:artistid/assist"} exact element={<ArtistEditAssistContainer />} />
                            <Route path={"/artists"} exact element={<ArtistListContainer />} />
                            <Route path={"/artists/:artistid"} exact element={<ArtistHomeContainer />}>
                                <Route index element={<ArtistEditBaseContainer />} />
                                <Route path={"base"} exact element={<ArtistEditBaseContainer />} />
                                <Route path={"mail"} exact element={<ArtistEditMailContainer />} />
                                <Route path={"hours"} exact element={<ArtistEditBusinessHoursContainer />} />
                                <Route path={"locations"} exact element={<ArtistEditLocationContainer />} />
                                <Route path={"rights"} exact element={<ArtistEditRightsContainer />} />
                                <Route path={"assist"} exact element={<ArtistEditAssistContainer />} />
                            </Route>
                            <Route path={TEMPLATE_BASE_PATH} exact element={<VorlagenHomeContainer />}>
                                <Route index element={<MessageTemplateListContainer />} />
                                <Route
                                    path={AUTO_MESSAGE_SETTINGS_SUBPATH}
                                    element={<AutoMessageSettingsContainer />}
                                />
                                <Route
                                    path={`${MESSAGES_SUBPATH}/:msgVorlageId`}
                                    element={<MessageTemplateEditContainer />}
                                />
                                <Route path={VOUCHERS_SUBPATH} element={<VoucherTemplateListContainer />} />
                                <Route path={FORMS_TEMPLATE_SUBPATH} element={<FormsListContainer />} />
                            </Route>
                            <Route path={`${FORMS_EDITOR_PATH}/:formsId`} element={<FormEditContainer />} />
                            <Route path={STUDIO_BASEPATH} exact element={<StudioHomeContainer />}>
                                <Route index element={<EditStudioBaseContainer />} />
                                <Route path={STUDIO_BASE_SUBPATH} element={<EditStudioBaseContainer />} />
                                <Route path={STUDIO_SUBSCRIPTION_SUBPATH} element={<EditStudioAboContainer />} />
                                <Route path={STUDIO_LOCATIONS_SUBPATH} element={<LocationListContainer />} />
                                <Route
                                    path={STUDIO_APPOINTMENT_TYPES_SUBPATH}
                                    element={<AppointmentTypesContainer />}
                                />
                                <Route path={STUDIO_GGL_SYNC_SUBPATH} element={<GoogleSyncStudioSetting />} />
                                <Route path={STUDIO_V2_API_SUBPATH} element={<V2ApiSettings />} />
                                <Route path={STUDIO_INVOICES_SUBPATH} element={<StudioInvoiceListContainer />} />
                            </Route>
                            <Route path={"/lists/kunde/:reportdefid"} exact element={<KundenListContainer />} />
                            <Route
                                path={"/lists/dblexclusions/:reportdefid"}
                                exact
                                element={<DblExclusionsListContainer />}
                            />
                            <Route
                                path={"/lists/doubletten/:reportdefid"}
                                exact
                                element={<KundenDoublettenListContainer />}
                            />
                            <Route path={"/lists/projekt/:reportdefid"} exact element={<ProjektListContainer />} />
                            <Route path={"/lists/termin/:reportdefid"} exact element={<TerminListContainer />} />
                            <Route path={"/lists/einnahmen/:reportdefid"} exact element={<ListEinnahmenContainer />} />
                            <Route path={"/lists/jobs/:reportdefid"} exact element={<JobsListContainer />} />
                            <Route path={"/lists/checkout/:reportdefid"} exact element={<CheckoutListContainer />} />
                            <Route path={"/lists/vouchers/:reportdefid"} exact element={<VoucherOutListContainer />} />
                            <Route path={"/lists/vouchin/:reportdefid"} exact element={<VouchInListContainer />} />
                            <Route
                                path={"/lists/nachrichten/:reportdefid"}
                                exact
                                element={<NachrichtListContainer />}
                            />
                            <Route path={SEND_MESSAGE_PATH} exact element={<SendNachrichtContainer />} />
                            <Route path={KISSSIGN_BASEPATH} exact element={<KissSignHomeContainer />}>
                                <Route index element={<PadContainer />} />
                                <Route path={KISSSIGN_AUTO_DELIVERY_SUBPATH} element={<DokuVersandContainer />} />
                            </Route>
                            <Route path={"/pads"} exact element={<PadContainer />} />
                            <Route path={CUSTOMER_PORTAL_BASEPATH} exact element={<CustomerPortalContainer />} />
                            <Route path={"/lists"} exact element={<ListContainer />} />
                            <Route path={"/timeline"} exact element={<TimelineContainer />} />
                            <Route path={"/swagger-ui"} exact element={<SwaggerUI />} />
                            <Route path={"/error"} exact element={<BackendErrorContainer />} />
                        </Routes>
                        <SessionNotification
                            onClose={handleCloseNotification}
                            open={activeNotification !== undefined}
                            message={activeNotification?.message}
                            severity={activeNotification?.severity}
                        />
                    </React.Suspense>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </LocalizationProvider>
    );
};

export default connect()(App);
