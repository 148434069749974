import { combineReducers } from "redux";
import artistEditReducers from "./artist/artistReducer";
import authReducer from "./auth/authReducer";
import calendarReducer from "./calendar/CalendarReducer";
import commonReducers from './common/commonReducer';
import finalizerReducers from "./finalizer/finalizerReducer";
import kundeReducers from "./kunde/kundeReducer";
import kundenTermineReducers from "./kundenTermine/kundenTermineReducer";
import listReducers from "./list/listReducer";
import manageProjekteReducers from "./manage/projekte/manageProjekteReducer";
import nachrichtReducers from "./nachricht/nachrichtReducer";
import notificationReducers from "./notification/notificationReducer";
import projektReducers from "./projekt/projektReducer";
import routerReducer from "./router/routerReducers";
import kissSignReducers from "./settings/kiss-sign/kissSignReducer";
import templateReducers from "./templates/templateReducer";
import terminReducers from "./termin/terminReducer";
import timelineReducers from "./timeline/timelineReducers";

const createReducers = () => combineReducers({
    router: routerReducer,
    common: commonReducers,
    auth: authReducer,
    termin: terminReducers,
    kunde: kundeReducers,
    kundenTermine: kundenTermineReducers,
    calendar: calendarReducer,
    finalizer: finalizerReducers,
    manageProjekte: manageProjekteReducers,
    nachricht: nachrichtReducers,
    notification: notificationReducers,
    kissSignSettings: kissSignReducers,
    artistEdit: artistEditReducers,
    lists: listReducers,
    projekt: projektReducers,
    vorlagen: templateReducers,
    timeline: timelineReducers
});

export default createReducers;
