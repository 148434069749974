import React, { useMemo } from "react";
import DialogWithMenuContainer from "../common/menu/DialogWithMenuContainer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Alert, Box, Button, Grid2, Link, TextField, Typography } from "@mui/material";
import { nextFieldFocusHandler } from "../utils/inputHandler";
import { i18_TRANSLATION } from "../../i18nReferences";
import kisscalLogoWithText from "../../public/images/KissCalSiteLogo300px.jpg";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const HEADER_TEXT = "kisscal Service - Zugang zum Studio-Manager";

const Login = ({
    t,
    onSubmit,
    isLoginFailed,
    istLoggedIn,
    successText,
    loginFailedText,
    namespace,
    onLogoutStudio,
    nameText,
    onClickReset,
    loginResetError,
    loginResetResult,
}) => {
    const schema = yup.object().shape({
        name: yup.string().required(),
        password: yup.string().required(),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm({
        mode: "onTouched",
        defaultValues: {
            name: "",
            password: "",
        },
        resolver: yupResolver(schema),
    });

    const [name] = watch(["name"]);

    const renderFailureAlert = () => {
        if (!isLoginFailed && !istLoggedIn) {
            return null;
        }
        if (isLoginFailed) {
            return (
                <React.Fragment>
                    <Alert severity="warning">{loginFailedText}</Alert>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <Alert severity="success">{successText}</Alert>
            </React.Fragment>
        );
    };

    const loginResetSuccessKey = useMemo(
        () =>
            namespace === "Artist" ? "AUTH.RESET_ARTIST_LOGIN_SUCCESS_INFO" : "AUTH.RESET_STUDIO_LOGIN_SUCCESS_INFO",
        [namespace]
    );

    const renderLoginResetResult = () => {
        if (!loginResetResult) {
            return null;
        }
        return (
            (<Grid2>
                <Alert severity={loginResetError ? "error" : "success"}>
                    {loginResetError
                        ? t("AUTH.RESET_LOGIN_FAILURE_INFO", {
                              ns: i18_TRANSLATION,
                              error: loginResetError,
                          })
                        : t(loginResetSuccessKey, { ns: i18_TRANSLATION })}
                </Alert>
            </Grid2>)
        );
    };

    return (
        (<DialogWithMenuContainer t={t} maxWidth="sm" onExit={onLogoutStudio} logoutExit headerText={HEADER_TEXT}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid2 container alignContent="center" flexDirection="column" spacing={2}>
                    <Grid2>
                        <Typography variant="h2" color="text.primary">{`Login ${namespace}`}</Typography>
                    </Grid2>
                    <Grid2>
                        <TextField
                            autoFocus
                            fullWidth
                            {...register("name")}
                            onKeyUp={nextFieldFocusHandler}
                            error={!!errors?.name}
                            required
                            variant="standard"
                            label={nameText || "Name"}
                        />
                    </Grid2>
                    <Grid2>
                        <TextField
                            fullWidth
                            {...register("password")}
                            error={!!errors?.password}
                            required
                            type="password"
                            variant="standard"
                            label="Password"
                        />
                    </Grid2>
                    <Grid2>
                        <Button
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            disabled={istLoggedIn}
                            variant="contained"
                        >
                            ok
                        </Button>
                    </Grid2>
                    <Grid2 sx={{ marginTop: "10px" }}>
                        {renderFailureAlert()}
                    </Grid2>
                    {name?.length > 2 ? (
                        <Grid2 sx={{ marginTop: "20px" }}>
                            <Link onClick={() => onClickReset(name)} sx={{ cursor: "pointer" }}>
                                Reset Password
                            </Link>
                        </Grid2>
                    ) : null}
                    {renderLoginResetResult()}

                    {namespace !== "Artist" ? (
                        <Grid2 sx={{ marginTop: "40px" }}>
                            <Box display="grid" onClick={() => console.log("click")} gap={2}>
                                <Box display="flex" alignItems="center" gap={1}>
                                    <OpenInNewIcon />
                                    <Link href="https://kisscal.tattoo" sx={{ fontWeight: "bold" }}>
                                        zu unseren Produkten
                                    </Link>
                                </Box>
                                <Box
                                    component="img"
                                    height="80px"
                                    src={kisscalLogoWithText}
                                    alt="KissCal Logo"
                                    sx={{ "&:hover": { cursor: "pointer" } }}
                                    onClick={() => window.location.href = "https://kisscal.tattoo"}
                                ></Box>
                            </Box>
                        </Grid2>
                    ) : null}
                </Grid2>
            </form>
        </DialogWithMenuContainer>)
    );
};

export default Login;
