import * as yup from "yup";
import { i18_EDITPROJEKT, i18_EDITTERMIN, i18_KUNDE, i18_LIST, i18_TRANSLATION } from "../i18nReferences";
import { getDatePartAtTimezone, isValidDate } from "../store/utils/dateHelper";
import { transformNoNumberToUndefined } from "../store/utils/yupSchema";
import { hatRecht, RECHT_EINNAHMENSCHREIBEN, RECHT_LOESCHEN, RECHT_SCHREIBEN } from "./auth";
import { isAnzahlung } from "./einnahme";
import {
    TERMINSTATI_MAP,
    TERMINSTATUS_AUSGEFALLEN, TERMINSTATUS_ERLEDIGT,
    TERMINSTATUS_OFFEN,
    TERMINSTATUS_VORGEMERKT,
    TERMINTYPEN_DEPOSIT
} from "./terminStatus";
import { pick } from "../store/utils/lodash-replacement";

export const isTerminForArtist = (termin, artistId) => termin.ArtistID === artistId && !termin.AttArtistID || termin.AttArtistID === artistId;
export const isTerminForArtistList = (termin, artistIdList) => artistIdList.includes(termin.ArtistID) && !termin.AttArtistID
    || termin.AttArtistID && artistIdList.includes(termin.AttArtistID);

export const DEPOSIT_MEETING_TEXT_PART = 'Termine+AZ'
export const isDepositMeeting = (termin) => termin.Betreff && termin.Betreff.indexOf(DEPOSIT_MEETING_TEXT_PART) >= 0

export const TERMINTYP_PRIVAT = 5

export const canDeleteTermin = (termin, loggedInArtist, rechteMap) => {
    if (loggedInArtist.CannotEdit) {
        return false
    }
    if (loggedInArtist.isAdmin || termin._createdID === loggedInArtist.ArtistID) {
        return true
    }
    const recht = rechteMap && rechteMap[termin.ArtistID]
    if (recht) {
        return hatRecht(recht, [RECHT_LOESCHEN])
    }

    return false
}

export const canArtistEditTermin = (selectedArtistId, loggedInArtist, rechteMap) => {
    if (loggedInArtist.isAdmin) {
        return true
    }
    if (loggedInArtist.CannotEdit) {
        return false
    }
    const recht = rechteMap && rechteMap[selectedArtistId]
    if (recht) {
        return hatRecht(recht, [RECHT_SCHREIBEN])
    }
    return false
}

export const canArtistEditEinnahmen = (selectedArtistId, loggedInArtist, rechteMap) => {
    if (loggedInArtist.CannotEdit) {
        return false
    }
    if (selectedArtistId === loggedInArtist.ArtistID) {
        return true
    }
    const recht = rechteMap && rechteMap[selectedArtistId]
    if (recht) {
        return hatRecht(recht, [RECHT_EINNAHMENSCHREIBEN])
    }
    return false
}


export const createTerminSchema = () => {
    return yup.object().shape({
        Start: yup.date().min(new Date('1970-01-01')).required(),
        DatBis: yup.date().min(yup.ref('Start'), {key: 'YUP.ERROR.START_AFTER_END'}).required(),
        Betreff: yup.string().max(60).nullable(true),
        ArtistID: yup.number().when(['$artistIdListWrite'], ([artistIdListWrite], schema) => schema.oneOf(artistIdListWrite, {key: 'YUP.ERROR.KEINE_RECHTE'}))
    })
}

export const isGespeicherterTermin = terminId => terminId > 0;

export const getAnzahlungsEinnahmen = termin => termin && termin.einnahmen
    && TERMINTYPEN_DEPOSIT.includes(termin.Typ)
    && termin.einnahmen.filter(e => !isAnzahlung(e)).map(t => t.Betrag).reduce((sum, amt) => sum + Math.round(amt * 100) / 100, 0)

export const getAnzahlungsVerrechnungen = termin => termin && termin.einnahmen
    && ! TERMINTYPEN_DEPOSIT.includes(termin.Typ)
    && termin.einnahmen.filter(e => isAnzahlung(e)).map(t => t.Betrag).reduce((sum, amt) => sum + Math.round(amt * 100) / 100, 0)

export const convertTerminToSchema = (termin) => ({
    ...pick(termin, ['ArtistID', 'Typ', 'TrmStat', 'Ganztags', 'Betreff', 'Bemerkung'])
    , Start: new Date(termin.Start)
    , DatBis: new Date(termin.DatBis)
})

export const convertTerminSchemaToTermin = (termin) => ({
    ...termin
    , Start: isValidDate(termin.Start) ? termin.Start.toISOString() : undefined
    , DatBis: isValidDate(termin.DatBis) ? termin.DatBis.toISOString() : undefined
})


export const createHolidayParams = (termin, timezone) => {
    if (!termin.Start || typeof termin.Start !== 'string' && !isValidDate(termin.Start)) {
        return undefined
    }
    const start = termin.Start.toISOString !== undefined ? getDatePartAtTimezone(termin.Start.toISOString(), timezone)
        : getDatePartAtTimezone(termin.Start, timezone)
    return ({
        artistId: termin.ArtistID
        , start: start
        , end: start
    });
}

export const createCalculatorSchema = () => {
    return yup.object().shape({
        stunden: yup.number().transform(transformNoNumberToUndefined),
        minuten: yup.number().transform(transformNoNumberToUndefined).max(60).min(-60),
        preis: yup.number().transform(transformNoNumberToUndefined).min(0).required(),
    })
}

export const getTranslatedTerminstatusSelections = (t) => Object.keys(TERMINSTATI_MAP).map(key =>
    ({id: key, text: t(key, {ns: i18_TRANSLATION})}))
export const getTranslatedTerminStatusMap = (t) => Object.keys(TERMINSTATI_MAP).reduce((pv, cv) => {
    pv[TERMINSTATI_MAP[cv]] = t(cv, {ns: i18_TRANSLATION})
    return pv
}, {})

export const getDuration = (termin) => termin && (new Date(termin.DatBis).getTime() - new Date(termin.Start).getTime()) / 1000 / 60

export const getTerminListColumns = ({
                                         t,
                                         artistMap,
                                         translatedProjektStatusMap,
                                         translatedTerminStatusMap,
                                         tatortMap,
                                         termintypMap,
                                         translatedMsgPrefMap
                                     }) => [
    {field: 'KundID', type: 'number', headerName: t('LIST.KUNDID_LABEL', {ns: i18_LIST})},
    {field: 'ProjektID', type: 'number', headerName: t('LIST.PROJEKTID_LABEL', {ns: i18_LIST})},
    {field: 'TerminID', type: 'number', headerName: t('LIST.TERMINID_LABEL', {ns: i18_LIST})},
    {field: 'Vorname', headerName: t('EDIT_KUNDE.VORNAME_LABEL', {ns: i18_KUNDE})},
    {field: 'Nachname', headerName: t('EDIT_KUNDE.NACHNAME_LABEL', {ns: i18_KUNDE})},
    {field: 'gesperrt', type: 'boolean', headerName: t('EDIT_KUNDE.GESPERRT_LABEL', {ns: i18_KUNDE})},
    {field: 'KannKuFri', type: 'boolean', headerName: t('EDIT_KUNDE.KANN_KURZFRISTIG_LABEL', {ns: i18_KUNDE})},
    {
        field: 'MsgPref',
        headerName: t('EDIT_KUNDE.MSGPREF_LABEL', {ns: i18_KUNDE}),
        valueGetter: (value) => value !== undefined && translatedMsgPrefMap && translatedMsgPrefMap[value]
    },
    {field: 'Mobil', headerName: t('EDIT_KUNDE.MOBILE_LABEL', {ns: i18_KUNDE}), minWidth: 150},
    {field: 'Email', headerName: t('EDIT_KUNDE.EMAIL_LABEL', {ns: i18_KUNDE}), minWidth: 200},
    {field: 'ProjName', headerName: t('EDIT_PROJEKT.PROJNAME_LABEL', {ns: i18_EDITPROJEKT}), minWidth: 250},
    {
        field: 'ProjektArtistID',
        headerName: t('TERMIN_LIST.PROJEKT_ARTIST_LABEL', {ns: i18_LIST}),
        valueGetter: (value) => value && artistMap && artistMap[value].Kuerzel
    },
    {
        field: 'ProjStat',
        headerName: t('TERMIN_LIST.PROJEKT_STATUS_LABEL', {ns: i18_LIST}),
        valueGetter: (value) => value !== undefined && translatedProjektStatusMap && translatedProjektStatusMap[value]
    },
    {
        field: 'AnzAgreed',
        headerName: t('EDIT_PROJEKT.VEREINBARTE_ANZAHLUNG_LABEL', {ns: i18_EDITPROJEKT}),
        type: 'number'
    },
    {
        field: 'AnzStatus',
        headerName: t('EDIT_PROJEKT.ANZAHLUNGSSTATUS_LABEL', {ns: i18_EDITPROJEKT}),
        type: 'number'
    },
    {
        field: 'DiffAnzSaldo',
        headerName: t('PROJEKT_LIST.ANZAHLUNG_UNVOLLSTAENDIG_LABEL', {ns: i18_EDITPROJEKT}),
        type: 'boolean',
        valueGetter: (value, row) => +row.AnzAgreed > (+row.AnzStatus || 0)
    },
    {
        field: 'AnzInfo',
        headerName: t('EDIT_PROJEKT.BEMERKUNG_VEREINBARTE_ANZAHLUNG_LABEL', {ns: i18_EDITPROJEKT}),
        type: 'string'
    },
    {
        field: 'Preis',
        headerName: t('EDIT_PROJEKT.PREIS_LABEL', {ns: i18_EDITPROJEKT}),
        minWidth: 150,
    },
    {
        field: 'ProjektBemerkung',
        headerName: t('EDIT_PROJEKT.BEMERKUNG_LABEL', {ns: i18_EDITPROJEKT}),
        type: 'string',
        minWidth: 250,
        valueGetter: (value) => value && value.replaceAll("\n", " ")
    },
    {
        field: 'TatOrtID',
        headerName: 'Location',
        type: 'string',
        minWidth: 150,
        valueGetter: (value) => value && tatortMap && tatortMap[value].Ort
    },
    {
        field: 'ArtistID',
        headerName: 'Artist',
        valueGetter: (value) => value && artistMap && artistMap[value].Kuerzel
    },
    {
        field: 'Typ',
        headerName: t('EDIT_TERMIN.TERMINTYP_LABEL', {ns: i18_EDITTERMIN}),
        valueGetter: (value) => value !== undefined && termintypMap && termintypMap[value].Termintyp
    },
    {
        field: 'TrmStat',
        headerName: t('EDIT_TERMIN.TERMINSTATUS_LABEL', {ns: i18_EDITTERMIN}),
        valueGetter: (value) => value !== undefined && translatedTerminStatusMap && translatedTerminStatusMap[value]
    },
    {
        field: 'Start',
        type: 'dateTime',
        headerName: t('EDIT_TERMIN.START_LABEL', {ns: i18_TRANSLATION}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    },
    {
        field: 'DatBis',
        type: 'dateTime',
        headerName: t('EDIT_TERMIN.END_LABEL', {ns: i18_TRANSLATION}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    },
    {
        field: 'Dauer',
        type: 'number',
        headerName: t('EDIT_TERMIN.DURATION_LABEL', {ns: i18_TRANSLATION}),
    },
    {field: 'Betreff', headerName: t('EDIT_TERMIN.BETREFF_LABEL', {ns: i18_EDITTERMIN}), minWidth: 150},
    {
        field: 'maxAnamnese',
        type: 'dateTime',
        headerName: t('TERMIN_LIST.ANAMNESE_LABEL', {ns: i18_LIST}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    },
    {
        field: 'fehltGueltigeAnamnese',
        type: 'boolean',
        headerName: t('TERMIN_LIST.ANAMNESE_FEHLT_LABEL', {ns: i18_LIST})
    },
    {
        field: 'maxDatenschutz',
        type: 'dateTime',
        headerName: t('TERMIN_LIST.DATENSCHUTZ_LABEL', {ns: i18_LIST}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    },
    {
        field: 'maxEinverstaendnis',
        type: 'dateTime',
        headerName: t('TERMIN_LIST.EINVERSTAENDNIS_LABEL', {ns: i18_LIST}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    },
    {
        field: 'maxElternEve',
        type: 'dateTime',
        headerName: t('TERMIN_LIST.ELTERN_EVE_LABEL', {ns: i18_LIST}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    },
    {
        field: 'maxTerminDoku',
        type: 'dateTime',
        headerName: t('TERMIN_LIST.TERMINDOKU_LABEL', {ns: i18_LIST}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    },
    {
        field: 'isDokuFehlerhaft',
        type: 'boolean',
        headerName: t('TERMIN_LIST.DOKU_FEHLER_LABEL', {ns: i18_LIST})
    }
]

export const getColorForTypAndTrmStat = (termintyp, trmStat) => {
    switch (trmStat) {
        case TERMINSTATUS_OFFEN:
            return '#' + termintyp.FarbeOffen
        case TERMINSTATUS_AUSGEFALLEN:
            return '#' + termintyp.FarbeCancel
        case TERMINSTATUS_VORGEMERKT:
            return '#' + termintyp.FarbePending
        case TERMINSTATUS_ERLEDIGT:
            return '#' + termintyp.FarbeErledigt
        default:
            return '#f45342'
    }
}



