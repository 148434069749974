import {isSameOrBefore, plus} from "@kiss-solutions/dateutils";
import * as yup from "yup";
import {getDatePartAtTimezone} from "../store/utils/dateHelper";
import {i18_KUNDE, i18_LIST, i18_TRANSLATION} from "../i18nReferences";

export const MSGPREF_SMS = 1
export const MSGPREF_EMAIL = 2
export const MSGPREF_NO_CONTACT = 3
export const NACHRICHTTYP_MAP = {
    MSGPREF_SMS, MSGPREF_EMAIL
}
export const MSGPREF_MAP = {
    ...NACHRICHTTYP_MAP, MSGPREF_NO_CONTACT
}
export const MSGPREF_MAP_TRANSLATION_KEY_MAP = Object.keys(MSGPREF_MAP).reduce((map, key) => {
    map[MSGPREF_MAP[key]] = key
    return map
}, {})

export const getTranslatedMsgPrefMap = (t, ns) => Object.keys(MSGPREF_MAP).reduce((map, key) => {
    map[MSGPREF_MAP[key]] = t(key, {ns})
    return map
}, {})

export const GENDER_UNSPECIFIED = -1
export const GENDER_MALE = 1
export const GENDER_FEMALE = 2
export const GENDER_THIRD = 3
export const GENDER_MAP = {GENDER_UNSPECIFIED, GENDER_MALE, GENDER_FEMALE, GENDER_THIRD}
export const SPECIFIED_GENDER_MAP = {GENDER_MALE, GENDER_FEMALE, GENDER_THIRD}

export const getTranslatedGenderMap = (t, ns) => Object.keys(SPECIFIED_GENDER_MAP).reduce((map, key) => {
    map[SPECIFIED_GENDER_MAP[key]] = t(key, {ns})
    return map
}, {})


export const istVolljaehrig = (birthday, compareDate = new Date()) => {
    const cutOff = plus(compareDate, -18, 'year')
    return isSameOrBefore(new Date(birthday), cutOff, 'date')
}

export const joinKundenName = kunde => [kunde.Vorname, kunde.Nachname].filter(t => !!t).join(' ')
export const enrichKundeWithJoinedName = kunde => ({...kunde, Name: joinKundenName(kunde)})

function createMessagePrefValidation(mobil, email, schema) {
    const validOptions = [MSGPREF_NO_CONTACT]
    if (mobil?.length > 5) {
        validOptions.push(MSGPREF_SMS)
    }
    if (email?.length > 5) {
        validOptions.push(MSGPREF_EMAIL)
    }
    return schema.oneOf(validOptions, {key: 'YUP.ERROR.KUNDE_MSGPREF'})
}

export const createDoublettenCheckSchema = () => yup.object().shape({
    Vorname: yup.string().max(30).required(),
    Nachname: yup.string().max(30).required(),
    Mobil: yup.string().max(30).nullable(true),
    Email: yup.string().max(50).email({key: 'YUP.ERROR.KUNDE_EMAIL_FORMAT'}).when(['Mobil'],
        ([mobil], schema) => mobil ? schema.optional() : schema.required({key: 'YUP.ERROR.KUNDE_EMAIL_AND_MOBILE_EMPTY'}))
})

export const createKundeSchema = () => {
    return yup.object().shape({
        Vorname: yup.string().max(30).required(),
        Nachname: yup.string().max(30).required(),
        Telefon: yup.string().max(30).nullable(true),
        Mobil: yup.string().max(30).nullable(true),
        Email: yup.string().max(50).email({key: 'YUP.ERROR.KUNDE_EMAIL_FORMAT'}).when(['Mobil'],
            ([mobil], schema) => mobil ? schema.optional() : schema.required({key: 'YUP.ERROR.KUNDE_EMAIL_AND_MOBILE_EMPTY'})),
        MsgPref: yup.number().required().when(['Mobil', 'Email'], ([mobil, email], schema) => createMessagePrefValidation(mobil, email, schema)),
        Beruf: yup.string().max(35).nullable(true),
        Strasse: yup.string().max(45).nullable(true),
        Plz: yup.string().max(6).nullable(true),
        Ort: yup.string().max(35).nullable(true),
        Land: yup.string().max(30).nullable(true),
    })
}

export const createKundeForImportSchema = () => {
    return yup.object().shape({
        Vorname: yup.string().max(30).nullable(true),
        Nachname: yup.string().max(30).when(['Vorname'], ([vorname], schema) => vorname ? schema.nullable(true) : schema.required()),
        Telefon: yup.string().max(30).nullable(true),
        Mobil: yup.string().max(30).nullable(true),
        Email: yup.string().max(50).email({key: 'YUP.ERROR.KUNDE_EMAIL_FORMAT'}).when(['Mobil'],
            ([mobil], schema) => mobil ? schema.optional() : schema.required({key: 'YUP.ERROR.KUNDE_EMAIL_AND_MOBILE_EMPTY'})),
        Beruf: yup.string().max(35).nullable(true),
        Birthday: yup.string().min(10).max(13).nullable(true),
        Strasse: yup.string().max(45).nullable(true),
        Plz: yup.string().max(6).nullable(true),
        Ort: yup.string().max(35).nullable(true),
        Land: yup.string().max(30).nullable(true),
    })
}

export const createKundeFromSchema = (kundeData, loggedInArtistId, timezone) => ({
    ...kundeData
    ,
    Name: joinKundenName(kundeData)
    ,
    MsgPref: kundeData.MsgPref || (kundeData.Mobil ? MSGPREF_SMS : undefined) || (kundeData.Email ? MSGPREF_EMAIL : undefined) || MSGPREF_NO_CONTACT
    ,
    gesperrt: kundeData.gesperrt !== undefined ? kundeData.gesperrt : false
    ,
    KannKuFri: kundeData.KannKuFri !== undefined ? kundeData.KannKuFri : false
    ,
    _createdID: kundeData._createdID || loggedInArtistId
    ,
    _createdTS: kundeData._createdTS || new Date().toISOString()
    ,
    Birthday: kundeData.Birthday && getDatePartAtTimezone(kundeData.Birthday, timezone)
})

export const convertKundeToSchema = kunde => ({
    ArtistID: kunde.ArtistID,
    Vorname: kunde.Vorname || '',
    Nachname: kunde.Nachname || '',
    Telefon: kunde.Telefon || '',
    Mobil: kunde.Mobil || '',
    Email: kunde.Email || '',
    MsgPref: kunde.MsgPref || '',
    gesperrt: kunde.gesperrt || false,
    KannKuFri: kunde.KannKuFri || false,
    Strasse: kunde.Strasse || '',
    Plz: kunde.Plz || '',
    Ort: kunde.Ort || '',
    Land: kunde.Land || '',
    Deseases: kunde.Deseases || '',
    Bemerkungen: kunde.Bemerkungen || '',
    Beruf: kunde.Beruf || '',
    Geschlecht: kunde.Geschlecht || -1,
    Birthday: kunde.Birthday && new Date(kunde.Birthday)
})

export const extendPhoneNumberWithPreDial = (phoneNumber, preDial, nationalPreDial, internationalPreDial) => {
    if (preDial && phoneNumber.length > 4 && /^[0-9]*$/.test(phoneNumber) && (!nationalPreDial || phoneNumber.startsWith(nationalPreDial)
        && (!internationalPreDial || !phoneNumber.startsWith(internationalPreDial)))) {
        return internationalPreDial + preDial.substring(1) + phoneNumber.substring(1)
    }
    return phoneNumber
}

export const KUNDEN_FIELD_LABEL_KEY_MAP = {
    Vorname: 'EDIT_KUNDE.VORNAME_LABEL',
    Nachname: 'EDIT_KUNDE.NACHNAME_LABEL',
    gesperrt: 'EDIT_KUNDE.GESPERRT_LABEL',
    Geschlecht: 'EDIT_KUNDE.GESCHLECHT_LABEL',
    Birthday: 'EDIT_KUNDE.BIRTHDAY_LABEL',
    Telefon: 'EDIT_KUNDE.TELEFON_LABEL',
    Mobil: 'EDIT_KUNDE.MOBILE_LABEL',
    Email: 'EDIT_KUNDE.EMAIL_LABEL',
    MsgPref: 'EDIT_KUNDE.MSGPREF_LABEL',
    KannKuFri: 'EDIT_KUNDE.KANN_KURZFRISTIG_LABEL',
    Strasse: 'EDIT_KUNDE.STRASSE_LABEL',
    Plz: 'EDIT_KUNDE.PLZ_LABEL',
    Ort: 'EDIT_KUNDE.ORT_LABEL',
    Land: 'EDIT_KUNDE.LAND_LABEL',
    Beruf: 'EDIT_KUNDE.BERUF_LABEL',
    Deseases: 'EDIT_KUNDE.DESEASES_LABEL',
    Bemerkungen: 'EDIT_KUNDE.BEMERKUNG_LABEL'
}

export const getKundenListColumns = (t) => {
    return [
        {field: 'KundID', type: 'number', headerName: t('LIST.KUNDID_LABEL', {ns: i18_LIST})},
        {field: 'Vorname', headerName: t('EDIT_KUNDE.VORNAME_LABEL', {ns: i18_KUNDE})},
        {field: 'Nachname', headerName: t('EDIT_KUNDE.NACHNAME_LABEL', {ns: i18_KUNDE})},
        {field: 'gesperrt', type: 'boolean', headerName: t('EDIT_KUNDE.GESPERRT_LABEL', {ns: i18_KUNDE})},
        {field: 'Geschlecht', headerName: t('EDIT_KUNDE.GESCHLECHT_LABEL', {ns: i18_KUNDE})},
        {field: 'Birthday', type: 'date', headerName: t('EDIT_KUNDE.BIRTHDAY_LABEL', {ns: i18_KUNDE})},
        {field: 'NearestBirthday', type: 'date', headerName: t('EDIT_KUNDE.NEAREST_BIRTHDAY_LABEL', {ns: i18_KUNDE})},
        {field: 'Telefon', headerName: t('EDIT_KUNDE.TELEFON_LABEL', {ns: i18_KUNDE})},
        {field: 'Mobil', headerName: t('EDIT_KUNDE.MOBILE_LABEL', {ns: i18_KUNDE}), minWidth: 150},
        {field: 'Email', headerName: t('EDIT_KUNDE.EMAIL_LABEL', {ns: i18_KUNDE}), minWidth: 200},
        {field: 'MsgPref', headerName: t('EDIT_KUNDE.MSGPREF_LABEL', {ns: i18_KUNDE})},
        {field: 'hasKontaktErr', type: 'boolean', headerName: t('KUNDEN_LISTE.HAS_KONTAKT_ERR_LABEL', {ns: i18_KUNDE})},
        {field: 'KannKuFri', type: 'boolean', headerName: t('EDIT_KUNDE.KANN_KURZFRISTIG_LABEL', {ns: i18_KUNDE})},
        {field: 'Strasse', headerName: t('EDIT_KUNDE.STRASSE_LABEL', {ns: i18_KUNDE})},
        {field: 'Plz', headerName: t('EDIT_KUNDE.PLZ_LABEL', {ns: i18_KUNDE})},
        {field: 'Ort', headerName: t('EDIT_KUNDE.ORT_LABEL', {ns: i18_KUNDE})},
        {field: 'Land', headerName: t('EDIT_KUNDE.LAND_LABEL', {ns: i18_KUNDE})},
        {field: 'Beruf', headerName: t('EDIT_KUNDE.BERUF_LABEL', {ns: i18_KUNDE}), minWidth: 200},
        {field: 'Deseases', headerName: t('EDIT_KUNDE.DESEASES_LABEL', {ns: i18_KUNDE}), minWidth: 250},
        {field: 'Bemerkungen', headerName: t('EDIT_KUNDE.BEMERKUNG_LABEL', {ns: i18_KUNDE}), minWidth: 250},
        {field: 'ArtistID', type: 'number', headerName: t('LIST.ARTISTID_LABEL', {ns: i18_LIST})},
        {field: 'Kuerzel', type: 'number', headerName: t('KUNDEN_LISTE.KUERZEL_LABEL', {ns: i18_KUNDE})},
        {field: '_createdKuerzel', headerName: t('CREATED_KUERZEL_LABEL', {ns: i18_TRANSLATION})},
        {field: '_createdTS', type: 'dateTime', headerName: t('CREATED_TIME_LABEL', {ns: i18_TRANSLATION})}
    ]
}

export const getInvalidKundenImportColumns = (t) => [
    {field: 'Vorname', headerName: t('EDIT_KUNDE.VORNAME_LABEL', {ns: i18_KUNDE})},
    {field: 'Nachname', headerName: t('EDIT_KUNDE.NACHNAME_LABEL', {ns: i18_KUNDE})},
    {field: 'Mobil', headerName: t('EDIT_KUNDE.MOBILE_LABEL', {ns: i18_KUNDE}), minWidth: 150},
    {field: 'Email', headerName: t('EDIT_KUNDE.EMAIL_LABEL', {ns: i18_KUNDE}), minWidth: 200},
    {field: 'Telefon', headerName: t('EDIT_KUNDE.TELEFON_LABEL', {ns: i18_KUNDE})},
    {field: 'Birthday', type: 'date', headerName: t('EDIT_KUNDE.BIRTHDAY_LABEL', {ns: i18_KUNDE})},
    {field: 'Plz', headerName: t('EDIT_KUNDE.PLZ_LABEL', {ns: i18_KUNDE})},
    {field: 'Ort', headerName: t('EDIT_KUNDE.ORT_LABEL', {ns: i18_KUNDE})},
    {field: 'Strasse', headerName: t('EDIT_KUNDE.STRASSE_LABEL', {ns: i18_KUNDE})},
    {field: 'Deseases', headerName: t('EDIT_KUNDE.DESEASES_LABEL', {ns: i18_KUNDE}), minWidth: 250},
    {field: 'Bemerkungen', headerName: t('EDIT_KUNDE.BEMERKUNG_LABEL', {ns: i18_KUNDE}), minWidth: 250}
]

export const isKundeChild = (kunde, date = new Date()) => kunde.Birthday != null && Math.floor((date - new Date(kunde.Birthday)) / 31557600000) < 18


export const getDblExclusionListColumns = (t, artistMap) => [
    {field: 'KdExDblID', type: 'number'},
    {field: 'KundIDFrom', type: 'number'},
    {field: 'KundIDTo', type: 'number'},
    {
        field: 'VonKunde',
        headerName: t('LIST_DBLEXCLUSION.VON_NAME_HEADER', {ns: i18_LIST}),
        valueGetter: (value, row) => joinKundenName({Vorname: row.FromVorname, Nachname: row.FromNachname}),
        minWidth: 250
    },
    {
        field: 'VonMobil',
        headerName: t('LIST_DBLEXCLUSION.VON_MOBIL_HEADER', {ns: i18_LIST}),
        minWidth: 150
    },
    {
        field: 'VonEmail',
        headerName: t('LIST_DBLEXCLUSION.VON_EMAIL_HEADER', {ns: i18_LIST}),
        minWidth: 150
    },
    {
        field: 'NachKunde',
        headerName: t('LIST_DBLEXCLUSION.NACH_NAME_HEADER', {ns: i18_LIST}),
        valueGetter: (value, row) => joinKundenName({Vorname: row.ToVorname, Nachname: row.ToNachname}),
        minWidth: 250
    },
    {
        field: 'NachMobil',
        headerName: t('LIST_DBLEXCLUSION.NACH_MOBIL_HEADER', {ns: i18_LIST}),
        minWidth: 150
    },
    {
        field: 'NachEmail',
        headerName: t('LIST_DBLEXCLUSION.NACH_EMAIL_HEADER', {ns: i18_LIST}),
        minWidth: 150
    },
    {
        field: 'Info',
        headerName: t('LIST_DBLEXCLUSION.INFO_HEADER', {ns: i18_LIST}),
        minWidth: 250
    },
    {
        field: '_createdID',
        headerName: t('LIST_DBLEXCLUSION.ERFASSER_HEADER', {ns: i18_LIST}),
        valueGetter: (value) => value && artistMap[value]?.Kuerzel || ''
    },
    {
        field: '_createdTS',
        type: 'dateTime',
        headerName: t('LIST_DBLEXCLUSION.ERFASST_HEADER', {ns: i18_LIST}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    }
]

export const getDoublettenListColumns = (t, artistMap) => [
    {field: 'id', type: 'number'},
    {field: 'kundIdFrom', type: 'number'},
    {field: 'kundIdTo', type: 'number'},
    {
        field: 'VonKunde',
        headerName: t('LIST_DBLEXCLUSION.VON_NAME_HEADER', {ns: i18_LIST}),
        valueGetter: (value, row) => joinKundenName({Vorname: row.fromVorname, Nachname: row.fromNachname}),
        minWidth: 200
    },
    {
        field: 'fromCleanMobil',
        headerName: t('LIST_DBLEXCLUSION.VON_MOBIL_HEADER', {ns: i18_LIST}),
        minWidth: 150
    },
    {
        field: 'fromEmail',
        headerName: t('LIST_DBLEXCLUSION.VON_EMAIL_HEADER', {ns: i18_LIST}),
        minWidth: 200
    },
    {
        field: 'fromCreatedID',
        headerName: t('LIST_DOUBLETTEN.VON_ERFASSER_HEADER', {ns: i18_LIST}),
        valueGetter: (value) => value && artistMap[value].Kuerzel
    },
    {
        field: 'fromCreatedTS',
        type: 'dateTime',
        headerName: t('LIST_DOUBLETTEN.VON_ERFASST_HEADER', {ns: i18_LIST}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    },
    {
        field: 'NachKunde',
        headerName: t('LIST_DBLEXCLUSION.NACH_NAME_HEADER', {ns: i18_LIST}),
        valueGetter: (value, row) => joinKundenName({Vorname: row.toVorname, Nachname: row.toNachname}),
        minWidth: 200
    },
    {
        field: 'toCleanMobil',
        headerName: t('LIST_DBLEXCLUSION.NACH_MOBIL_HEADER', {ns: i18_LIST}),
        minWidth: 150
    },
    {
        field: 'toEmail',
        headerName: t('LIST_DBLEXCLUSION.NACH_EMAIL_HEADER', {ns: i18_LIST}),
        minWidth: 200
    },
    {
        field: 'toCreatedID',
        headerName: t('LIST_DOUBLETTEN.NACH_ERFASSER_HEADER', {ns: i18_LIST}),
        valueGetter: (value) => value && artistMap[value].Kuerzel
    },
    {
        field: 'toCreatedTS',
        type: 'dateTime',
        headerName: t('LIST_DOUBLETTEN.NACH_ERFASST_HEADER', {ns: i18_LIST}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    },
    {
        field: 'Namensgleich',
        type: 'boolean',
        headerName: t('LIST_DOUBLETTEN.SAME_NAME', {ns: i18_LIST}),
        valueGetter: (value, row) => row && row.types.includes('Name')
    },
    {
        field: 'Emailgleich',
        type: 'boolean',
        headerName: t('LIST_DOUBLETTEN.SAME_EMAIL', {ns: i18_LIST}),
        valueGetter: (value, row) => row && row.types.includes('Email')
    },
    {
        field: 'Mobilgleich',
        type: 'boolean',
        headerName: t('LIST_DOUBLETTEN.SAME_MOBILE', {ns: i18_LIST}),
        valueGetter: (value, row) => row && row.types.includes('CleanMobil')
    }
]

export const convertDblExclusion2Kunden = (row) => ({
    id: row.id,
    fromKunde: joinKundenName({
        Vorname: row.fromVorname,
        Nachname: row.fromNachname
    }) + `, ${row.fromCleanMobil || ''}, ${row.fromEmail || ''}`,
    toKunde: joinKundenName({
        Vorname: row.toVorname,
        Nachname: row.toNachname
    }) + `, ${row.toCleanMobil || ''}, ${row.toEmail || ''}`
})

export const getMsgTyp = (kunde) => {
    if (kunde?.Mobil?.length > 5 && (kunde.MsgPref === MSGPREF_SMS || kunde.MSGPREF_NO_CONTACT || !kunde.Email)) {
        return MSGPREF_SMS
    } else if (kunde?.Email) {
        return MSGPREF_EMAIL
    } else {
        return null
    }
}

export const kundenImportMapper = (row, mapping, dateMapper) => Object.keys(row).reduce((result, key) => {
    if (row[key]?.length) {
        if (mapping[key]?.length) {
            if (mapping[key] === 'Birthday') {
                if (dateMapper && row[key]) {
                    const birthday = dateMapper(row[key])
                    if (birthday) {
                        result[mapping[key]] = birthday.toISOString().substring(0, 10)
                    }
                }
            } else if (mapping[key] === 'Email') {
                result[mapping[key]] = row[key].replace(/\s/g, '').trim()
            } else {
                result[mapping[key]] = row[key].trim()
            }
        }
    }
    return result
}, {})
