import { createSelector } from "reselect";

export const selectListsState = createSelector(
    [(state) => state]
    , (state) => state.lists
)

export const selectIsListDataLoading = createSelector([selectListsState], state => Boolean(state?.listDataLoading))
export const selectSelectedListIdList = createSelector([selectListsState], state => state?.selectedListIdList ?? [])
export const selectIsReportDefinitionsLoading = createSelector([selectListsState], state => Boolean(state?.allReportDefinitionsLoading))
export const selectKundenListData = createSelector([selectListsState], state => state?.kundenListData ?? [])
export const selectProjektListData = createSelector([selectListsState], state => state?.projektListData ?? [])
export const selectTerminListData = createSelector([selectListsState], state => state?.terminListData ?? [])
export const selectEinnahmenListData = createSelector([selectListsState], state => state?.einnahmenListData ?? [])
export const selectCheckoutListData = createSelector([selectListsState], state => state?.checkoutListData ?? [])
export const selectVouchInListData = createSelector([selectListsState], state => state?.vouchInListData ?? [])
export const selectDblExclusionListData = createSelector([selectListsState], state => state?.dblExclusionListData ?? [])
export const selectDoublettenListData = createSelector([selectListsState], state => state?.doublettenListData)
export const selectDoublettenMergeKundenData = createSelector([selectListsState], state => state?.mergeKundenData)
export const selectMandantenJobsListData = createSelector([selectListsState], state => state?.mandantenJobsListData ?? [])
export const selectProjektListParameters = createSelector([selectListsState], state => state?.projektParameters)
export const selectTerminListParameters = createSelector([selectListsState], state => state?.terminParameters)
export const selectReportDefinition = createSelector([selectListsState], state => state?.reportDefinition)
export const selectAllReportDefinitions = createSelector([selectListsState], state => state?.allReportDefinitions ?? [])

export const selectParameterForKundenList = createSelector([selectListsState], state => state?.kundenListParameter)
export const selectSelectedArtistIDForKundenList = createSelector([selectParameterForKundenList], state => state?.selectedArtistID)
export const selectAngelegtSeitForKundenList = createSelector([selectParameterForKundenList], state => state?.angelegtSeit ?? null)
export const selectletzterTerminNachForKundenList = createSelector([selectParameterForKundenList], state => state?.letzterTerminNach ?? null)
export const selectSelectedArtistIDListForProjektList = createSelector([selectListsState], state => state?.selectedArtistIdList)

export const selectParameterForTerminList = createSelector([selectListsState], state => state?.terminListParameter)
export const selectSelectedArtistIdListForTerminList = createSelector([selectParameterForTerminList], state => state?.selectedArtistIDList)
export const selectSelectedTatOrtIDListForTerminList = createSelector([selectParameterForTerminList], state => state?.selectedTatOrtIDList)
export const selectSelectedTermintypIdListForTerminList = createSelector([selectParameterForTerminList], state => state?.selectedTermintypIdList)
export const selectSelectedTerminstatusIdListForTerminList = createSelector([selectParameterForTerminList], state => state?.selectedTerminstatusIdList ?? [])
export const selectDatVonForTerminList = createSelector([selectParameterForTerminList], state => state?.datVon)
export const selectDatBisForTerminList = createSelector([selectParameterForTerminList], state => state?.datBis)

export const selectParameterForEinnahmenList = createSelector([selectListsState], state => state?.einnahmenListParameter)
export const selectSelectedArtistIdListForEinnahmenList = createSelector([selectParameterForEinnahmenList], state => state?.selectedArtistIDList)
export const selectSelectedTatOrtIDListForEinnahmenList = createSelector([selectParameterForEinnahmenList], state => state?.selectedTatOrtIDList)
export const selectDatVonForEinnahmenList = createSelector([selectParameterForEinnahmenList], state => state?.datVon)
export const selectDatBisForEinnahmenList = createSelector([selectParameterForEinnahmenList], state => state?.datBis ?? null)
export const selectZahlungsartTextForEinnahmenList = createSelector([selectParameterForEinnahmenList], state => state?.zahlungsart)

export const selectParameterForJobsList = createSelector([selectListsState], state => state?.jobsListParameter)
export const selectSelectedArtistIDForJobsList = createSelector([selectParameterForJobsList], state => state?.selectedArtistID)
export const selectAngelegtSeitForJobsList = createSelector([selectParameterForJobsList], state => state?.angelegtSeit ?? null)

export const selectParameterForVouchInList = createSelector([selectListsState], state => state?.vouchInListParameter)
export const selectDatInVonForVouchInList = createSelector([selectParameterForVouchInList], state => state?.datInVon ?? null)
export const selectDatInBisForVouchInList = createSelector([selectParameterForVouchInList], state => state?.datInBis ?? null)

export const selectJobDetails = createSelector([selectListsState], state => state?.jobDetails)
export const selectIsJobDetailsLoading = createSelector([selectListsState], state => Boolean(state?.loadingJobDetails))

export const selectStatusListForProjektList = createSelector([selectListsState], state => state?.projektStatusListe ?? [])
export const selectDatVonForProjektList = createSelector([selectListsState], state => state?.datVon ?? null)
export const selectDatBisForProjektList = createSelector([selectListsState], state => state?.datBis ?? null)
export const selectWithoutAppointmentFlagForProjektList = createSelector([selectListsState], state => Boolean(state?.withoutAppointments))

export const selectParameterForDblExclusionList = createSelector([selectListsState], state => state?.dblExclusionListParameter)
export const selectDatVonForDblExclusionList = createSelector([selectParameterForDblExclusionList], state => state?.datVon ?? null)
export const selectDatBisForDblExclusionList = createSelector([selectParameterForDblExclusionList], state => state?.datBis ?? null)

export const selectNachrichtenListData = createSelector([selectListsState], state => state?.nachrichtenListData ?? [])
export const selectParameterForNachrichtenList = createSelector([selectListsState], state => state?.nachrichtenListParameter)
export const selectDatVonForNachrichtenList = createSelector([selectParameterForNachrichtenList], state => state?.datVon)
export const selectDatBisForNachrichtenList = createSelector([selectParameterForNachrichtenList], state => state?.datBis ?? null)
export const selectSelectedArtistIdListForNachrichtenList = createSelector([selectParameterForNachrichtenList], state => state?.selectedArtistIDList)
