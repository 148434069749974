import {createSelector} from "reselect";
import {
    selectCalendarEventZeitraum,
    selectCalendarState,
    selectSelectedCalendarArtistID,
    selectViewConfigurationType
} from "./calendarSelector";
import {selectArtistMap, selectStudioTatortMap} from "../common/commonSelector";
import {MULTIPLE_ARTIST_VIEW} from "./CalendarReducer";
import {hasRubrik, isArtistAtLocationInZeitraum} from "../../models/artist";
import {createFieldSelector} from "../utils/selectors";
import {selectLeseRechtArtistIDList, selectLoggedInArtist} from "../auth/authSelector";

/*
 * This method only works if zeitraum is one day, otherweise too many locations will be excluded as
 * it is only queried if there is any location found betweend start and end and not if thy cover whole
 * period
 */
function isAnyArtistAtLocation(artists, tatortId, zeitraum) {
    const artistAtLocation = artists.find(artist => isArtistAtLocationInZeitraum(artist, tatortId, zeitraum))
    return artistAtLocation !== undefined
}

export const selectResourceFilter = createSelector(
    [selectCalendarState
    , selectLoggedInArtist]
    , (state, loggedInArtist) => state.resourceFilter || {tatortId: loggedInArtist?.StdOrtID || -1}
)

export const calculateRelevantArtistForResourceList = (artistMap, resourceFilter, viewableArtistIdList, eventZeitraum) => artistMap
        && resourceFilter
        && eventZeitraum
        && Object.values(artistMap)
            .filter(a => !a.Ausgeschieden && viewableArtistIdList.includes(a.ArtistID)
                && (!resourceFilter?.rubrikId || resourceFilter?.rubrikId < 0 || hasRubrik(a, resourceFilter?.rubrikId))
                && (!resourceFilter?.artistType || resourceFilter?.artistType < 0 || a.Typ === resourceFilter.artistType))
            .filter(a => isArtistAtLocationInZeitraum(a, resourceFilter.tatortId, eventZeitraum)) || undefined

export const selectRelevantArtistsForResourceList = createSelector(
    [selectArtistMap
    , selectResourceFilter
    , selectLeseRechtArtistIDList
    , selectCalendarEventZeitraum]
    , (artistMap, resourceFilter, viewableArtistIdList, eventZeitraum) => calculateRelevantArtistForResourceList(artistMap, resourceFilter, viewableArtistIdList, eventZeitraum)
)

export const selectResources = createSelector(
    [selectRelevantArtistsForResourceList
    , selectViewConfigurationType]
    , (artists, viewType) => artists
        && viewType === MULTIPLE_ARTIST_VIEW
        && artists
            .map(a => ({id: a.ArtistID, title: a.Kuerzel}))
            .sort((a1, a2) => a1.title > a2.title ? 1 : -1) || undefined
)

export const selectRelevantArtistIDList = createSelector(
    [selectSelectedCalendarArtistID
    , selectResources]
    , (selectedArtistID, resources) => resources && resources.map(r => r.id)
        || selectedArtistID && [selectedArtistID]
        || []
)
export const selectSelectedTatorIdOfResourceFilter = createFieldSelector(selectResourceFilter, 'tatortId')
export const selectSelectableLocations = createSelector(
    [selectArtistMap
    , selectStudioTatortMap
    , selectCalendarEventZeitraum]
    , (artistMap, tatortMap, zeitraum) => {
        if (!artistMap || !tatortMap || !zeitraum) {
            return []
        }
        const artists = Object.values(artistMap)
        return Object.values(tatortMap)
            .filter(tatort => tatort.Bookable)
            .filter(tatort => isAnyArtistAtLocation(artists, tatort.TatOrtID, zeitraum))
    }
)

export const selectRelevantArtistsForTerminslotSearch = createSelector(
    [selectArtistMap
    , selectViewConfigurationType
    , selectSelectedCalendarArtistID
    , selectLeseRechtArtistIDList
    , selectResourceFilter]
    , (artistMap, viewType, calendarArtistID, viewableArtistIdList, resourceFilter) =>  artistMap
        && ( viewType === MULTIPLE_ARTIST_VIEW ? Object.values(artistMap)
        .filter(a => !a.Ausgeschieden && viewableArtistIdList.includes(a.ArtistID)
            && (!resourceFilter?.rubrikId || resourceFilter?.rubrikId < 0 || hasRubrik(a, resourceFilter?.rubrikId))
            && (!resourceFilter?.artistType || resourceFilter?.artistType < 0 || a.Typ === resourceFilter.artistType))
                .map(a => a.ArtistID)
                : [calendarArtistID])
)

