import { createSelector } from "reselect";

export const selectTimelineState = createSelector(
    [(state) => state]
    , (state) => state.timeline
)

export const selectTimelineData = createSelector([selectTimelineState], state => state?.data)
export const selectTimelineHolidays = createSelector([selectTimelineData], state => state?.holidayPeriods)
export const selectTimelineArtistDataMap = createSelector([selectTimelineData], state => state?.artistData ?? [])
