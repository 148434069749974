import React, {useEffect, useMemo, useState} from 'react';
import {Box, Button, Grid2, Typography, useMediaQuery} from "@mui/material";
import {createDoublettenCheckSchema} from "../../../models/kunde";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import ArtistSelection from "../../common/ArtistSelection";
import SimpleTextfield from "../SimpleTextfield";
import KundenTabelle from "../KundenTabelle";

const NeuKundeDoublettenCheck = ({
                                    t,
                                    onAddKunde,
                                    commonState,
                                    onSearchDoubletten,
                                    doubletten,
                                    isDoublettenLoaded,
                                    onCancel,
                                    onSelectKunde,
                                    studioTatOrtID
                                }) => {
    const {tatortMap, artistMap, loggedInArtistId} = commonState
    const isTablet = useMediaQuery('(min-width:600px)');
    const schema = createDoublettenCheckSchema()
    const buttonRowRef = React.createRef()
    const [selectedKundID, setSelectedKundID] = useState()

    const {
        control,
        handleSubmit,
        formState: {errors},
        setValue,
        register,
        getValues
    } = useForm({
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        setValue('ArtistID', loggedInArtistId)
    }, [loggedInArtistId, setValue])

    useEffect(() => {
        let timer
        if (isDoublettenLoaded) {
            timer = setTimeout(() => buttonRowRef.current.scrollIntoView({behavior: 'smooth'}), 500)
        }
        return () => timer && clearTimeout(timer)
    }, [isDoublettenLoaded, buttonRowRef])

    const handleErrors = () => {
        console.log(errors)
    }

    const handleSelectKunde = () => {
        const data = getValues()
        onSelectKunde({
            kundId: selectedKundID,
            email: data.Email,
            mobil: data.Mobil
        })
    }

    const handleClickNeu = () => {
        onAddKunde(getValues())
    }

    const noEntryText = useMemo(() => isDoublettenLoaded ? t('NEUER_KUNDE.DOUBLETTEN_TABLE_NO_ENTRY')
        : t('NEUER_KUNDE.DOUBLETTEN_PLEASE_SEARCH'), [isDoublettenLoaded, t])

    return (
        (<form>
            <Typography variant="h4" mb={4}>{t('NEUER_KUNDE.DOUBLETTEN_HEADER')}</Typography>
            <Grid2 container spacing={2} mb={4}>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 4
                    }}>
                    <ArtistSelection control={control} tatortMap={tatortMap} artistsMap={artistMap}
                                     sx={{minWidth: '4em'}} studioTatOrtID={studioTatOrtID}
                                     controllerName="ArtistID" fullWidth={true}/>
                </Grid2>
                <Grid2
                    size={{
                        xs: 6,
                        sm: 4
                    }}>
                    <SimpleTextfield t={t} register={register} fieldName="Vorname" errors={errors} autoFocus={true}
                                     labelKey="EDIT_KUNDE.VORNAME_LABEL" fullWidth required/>
                </Grid2>
                <Grid2
                    size={{
                        xs: 6,
                        sm: 4
                    }}>
                    <SimpleTextfield t={t} register={register} fieldName="Nachname" errors={errors}
                                     labelKey="EDIT_KUNDE.NACHNAME_LABEL" fullWidth required/>
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 6
                    }}>
                    <SimpleTextfield t={t} register={register} fieldName="Mobil" errors={errors}
                                     labelKey="EDIT_KUNDE.MOBILE_LABEL" fullWidth required/>
                </Grid2>
                <Grid2
                    size={{
                        xs: 12,
                        sm: 6
                    }}>
                    <SimpleTextfield t={t} register={register} fieldName="Email" errors={errors}
                                     labelKey="EDIT_KUNDE.EMAIL_LABEL" fullWidth/>
                </Grid2>
                <Grid2 container justifyContent="flex-end" size={12}>
                    <Button variant="contained" onClick={handleSubmit(onSearchDoubletten, handleErrors)}>
                        {t('NEUER_KUNDE.DOUBLETTEN_SEARCH_BUTTON_TEXT')}
                    </Button>
                </Grid2>
            </Grid2>
            <Typography variant="h6">{t('NEUER_KUNDE.DOUBLETTEN_TABLE_HEADER')}</Typography>
            <KundenTabelle t={t} kunden={doubletten} noEntryText={noEntryText} onChange={id => setSelectedKundID(id)}
                           selectedKundId={selectedKundID}/>
            {isDoublettenLoaded &&
            <Box display="flex" mt={4} mb={2} flexDirection={!isTablet ? 'column-reverse' : 'row'} gap="1.5em"
                 justifyContent="flex-end" ref={buttonRowRef}>
                <Button fullWidth={!isTablet} variant="contained" color="error" onClick={onCancel}>Cancel</Button>
                <Button fullWidth={!isTablet} variant="contained" onClick={handleClickNeu}
                        color="success">{t('NEUER_KUNDE.DOUBLETTEN_BUTTON_NEU')}</Button>
                {selectedKundID ? <Button fullWidth={!isTablet} variant="contained"
                                          onClick={handleSelectKunde}
                                          color="warning">{t('NEUER_KUNDE.DOUBLETTEN_KUNDE_UEBERNEHMEN')}</Button> : null}

            </Box>}
        </form>)
    );
}

export default NeuKundeDoublettenCheck;