import { createSelector } from "reselect";

export const selectNotificationState = createSelector(
    [(state) => state]
    , (state) => state.notification
)

export const selectActiveNotification = createSelector([selectNotificationState], state => state?.activeNotification)
export const selectAllButInfoNotification = createSelector(
    [selectActiveNotification]
    , (notification) => notification && notification?.severity !== 'info' ? {...notification} : undefined
)
export const selectLastNotifications = createSelector([selectNotificationState], state => state?.lastNotifications)
