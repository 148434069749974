import { createSelector } from "reselect";
import { constructTitle } from "../../components/calendar/eventModel";
import { convertImagesRaw2ProjektImages } from "../../models/projekt";
import { selectArtistMap } from "../common/commonSelector";
import { keyBy } from "../utils/lodash-replacement";
import { TERMIN_DATA_NAME } from "./terminReducer";

export const selectTerminState = createSelector(
    [(state) => state]
    , (state) => state?.termin
)

export const selectTerminData = createSelector([selectTerminState], state => state && state[TERMIN_DATA_NAME])
export const selectTerminKunden = createSelector([selectTerminState], state => state?.terminKunden ?? [])
export const selectTerminEditState = createSelector([selectTerminState], state => state?.editState ?? {})
export const selectTerminEditStateTermin = createSelector([selectTerminEditState], state => state?.termin ?? {})
export const selectTerminHoliday = createSelector([selectTerminState], state => state?.terminHoliday)
export const selectTermin = createSelector([selectTerminData], state => state && state?.termin)
export const selectIsTerminDataLoading = createSelector([selectTerminState], state => Boolean(state?.terminDataLoading))
export const selectSelectedTerminKundID = createSelector(
    [selectTermin
    , selectTerminEditStateTermin]
    , (termin, terminChanges) => terminChanges?.KundID !== undefined ? terminChanges.KundID : termin?.KundID
)
export const selectTerminOwnerArtist = createSelector(
    [selectTermin
    , selectArtistMap]
    , (termin, artistMap) => termin && artistMap && artistMap[termin.ArtistID]
)
export const selectSelectedTerminArtistID = createSelector(
    [selectTermin
    , selectTerminEditStateTermin]
    , (termin, terminChanges) => terminChanges?.ArtistID !== undefined ? terminChanges.ArtistID : termin?.ArtistID
)
export const selectTerminTitle = createSelector(
    [selectTermin
    , selectTerminOwnerArtist]
    , (termin, artist) => termin && artist && constructTitle({termin, artistKuerzel: artist.Kuerzel})
)

export const selectSelectableTerminKundenMap = createSelector(
    [selectTerminData
    , selectTerminKunden]
    , (terminData, terminKunden) => terminData && keyBy((terminData.kunde && [terminData.kunde] || []).concat(terminKunden), 'KundID') || {}
)
export const selectSelectableProjekteOfTermin = createSelector([selectTerminData], state => state?.selectableProjekte)
export const selectSelectedProjektOfTermin = createSelector([selectTerminData], state => state?.projekt)
export const selectSelectedProjektBaseOfTermin = createSelector(
    [selectTerminData]
    , terminData => terminData?.projektBase || terminData?.projekt
)

export const selectProjektImagesRaw = createSelector([selectTerminData], state => state?.projektImages ?? [])
export const selectProjektImages = createSelector(
    [selectProjektImagesRaw]
    , (projektImages) => convertImagesRaw2ProjektImages(projektImages)
)
export const selectInsertedTempImages = createSelector([selectTerminEditState], state => state?.insertedImages ?? [])
export const selectDeletedProjektImages = createSelector([selectTerminEditState], state => state?.deletedImages ?? [])

export const selectIsUploadingImages = createSelector([selectTerminState], state => Boolean(state?.uploadingImages))
export const selectDeletedProjektImageKeys = createSelector(
    [selectDeletedProjektImages]
    , images => images.map(t => t.key)
)

export const selectEditTerminImages = createSelector(
    [selectProjektImages
    , selectDeletedProjektImageKeys
    , selectInsertedTempImages]
    , (projektImages, deletedKeys, insertedImages) => projektImages.filter(t => !deletedKeys.includes(t.key))
        .concat(insertedImages.map(key =>({type: 'temp', key})))
)

export const selectTerminEditEinnahmeVoucher = createSelector([selectTerminState], state => state?.editEinnahmeVoucher)
export const selectTerminEditEinnahmen = createSelector([selectTerminData], state => state?.einnahmen ?? [])
export const selectTerminEditTeilnehmer = createSelector([selectTerminData], state => state?.teilnehmer ?? [])
export const selectIsSavingTermin = createSelector([selectTerminState], state => Boolean(state?.isSaving))
export const selectICalTermineCounters = createSelector([selectTerminState], state => state?.iCalTermineCounters)
export const selectImportedICalTermineCount = createSelector(
    [selectICalTermineCounters]
    , counters => (counters?.success || 0) + (counters?.failed || 0) + (counters?.skipped || 0)
)
export const selectIsImportingICalTermine = createSelector([selectTerminState], state => Boolean(state?.iCalTermineImporting))
export const selectIsImportingICalResetRequested = createSelector([selectTerminState], state => Boolean(state?.importICalResetRequested))
