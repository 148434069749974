import {createSimpleAction, createTypeAction} from "../../utils/actionUtils";

export const LOAD_PADS = 'LOAD_PADS'
export const loadPadsAction = createTypeAction(LOAD_PADS)

export const LOAD_PADS_SUCCESS = 'LOAD_PADS_SUCCESS'
export const loadPadsSuccessAction = createSimpleAction(LOAD_PADS_SUCCESS, 'pads')

export const SET_NEW_PAD_SECRET = 'SET_NEW_PAD_SECRET'
export const setNewPadSecretAction = createSimpleAction(SET_NEW_PAD_SECRET, 'pad')

export const DELETE_PAD = 'DELETE_PAD'
export const deletePadAction = createSimpleAction(DELETE_PAD, 'padId')

export const INSERT_PAD = 'INSERT_PAD'
export const insertPadAction = createSimpleAction(INSERT_PAD, 'pad')

export const CHANGE_DOKU_VERSAND_ARTIST = 'CHANGE_DOKU_VERSAND_ARTIST'
export const changeDokuVersandArtistAction = createSimpleAction(CHANGE_DOKU_VERSAND_ARTIST, 'artistId')