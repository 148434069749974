import { fork } from 'redux-saga/effects';
import { watchLoadCommon } from "./sagas/bootstrapCommon";
import { watchCreateSupportTicket } from "./sagas/createSupportTicketSaga";
import { watchDeleteArtistLocation } from "./sagas/deleteArtistLocation";
import { watchDeleteLocation } from "./sagas/deleteStudioLocationSaga";
import { watchDisableArtistInfo } from "./sagas/disableArtistInfo";
import { watchInsertArtistLocation } from "./sagas/insertArtistLocation";
import { watchMoveCalendarLocation } from "./sagas/moveArtistLocation";
import { watchReloadArtists } from "./sagas/reloadArtists";
import { watchReloadMsgVorlagen } from "./sagas/reloadMsgVorlagen";
import { watchReloadStudio } from "./sagas/reloadStudio";
import { watchSaveStudioLocation } from "./sagas/saveLocationSaga";
import { watchUpdateArtistLocation } from "./sagas/updateArtistLocation";
import { watchUpdateStudio } from "./sagas/updateStudio";

const commonSagas = [fork(watchLoadCommon), fork(watchReloadArtists)
    , fork(watchDisableArtistInfo), fork(watchInsertArtistLocation)
    , fork(watchDeleteArtistLocation), fork(watchUpdateArtistLocation), fork(watchMoveCalendarLocation)
    , fork(watchReloadMsgVorlagen), fork(watchReloadStudio), fork(watchUpdateStudio)
    , fork(watchDeleteLocation), fork(watchSaveStudioLocation), fork(watchCreateSupportTicket)]

export default commonSagas
