import {BACKEND} from "../config/backend";
import axios from "axios";
import {createAuthHeader} from "./commonApi";

export const VOUCHER_BASE_URL = `${BACKEND}/api/v1/voucher`

export const getVoucherByVouchNo = ({token, voucherNo}) => {
    return axios.get(`${VOUCHER_BASE_URL}/${voucherNo}`, {
        ...createAuthHeader(token)
    })
}

export const getVoucherListByDatOutVonBis = ({token, datOutVon, datOutBis}) => {
    return axios.get(`${VOUCHER_BASE_URL}/list`, {
        ...createAuthHeader(token)
        , params: {datOutVon, datOutBis}
    })
}

export const getVouchInListByDatOutVonBis = ({token, datInVon, datInBis}) => {
    return axios.get(`${VOUCHER_BASE_URL}/vouchin/list`, {
        ...createAuthHeader(token)
        , params: {datInVon, datInBis}
    })
}

export const updateVoucher = ({token, voucher}) => {
    return axios.put(`${VOUCHER_BASE_URL}`, voucher,{
        ...createAuthHeader(token)
    })
}

export const insertVoucher = ({token, voucher}) => {
    return axios.post(`${VOUCHER_BASE_URL}`, voucher,{
        ...createAuthHeader(token)
    })
}