import React from 'react';
import {TextField} from "@mui/material";
import {getHelperText} from "../../yupLocalization";

const SimpleTextfield = ({t, register, errors, fieldName, labelKey, additionalHelper, shrinkLabel, helperTextNamespace, labelNamespace, fullWidth=true, ...rest}) => {
    return (
        <TextField
            {...rest}
            {...register(fieldName)}
            fullWidth={fullWidth}
            slotProps={{
                inputLabel: { shrink: shrinkLabel }
            }}
            error={errors && !!errors[fieldName]}
            helperText={getHelperText(t, errors, fieldName, helperTextNamespace) || additionalHelper}
            variant="standard"
            label={labelNamespace ? t(labelKey, {ns: labelNamespace}) : t(labelKey)}/>
    )
}

export default SimpleTextfield;