import { v4 as uuid } from 'uuid';
import * as yup from "yup";
import { i18_EDITTERMIN, i18_EINNAHMEN, i18_KUNDE, i18_LIST, i18_TRANSLATION } from "../i18nReferences";
import { createValueFormatter } from "./lists";
import { TERMINSTATUS_AUSGEFALLEN, TERMINTYPEN_DEPOSIT } from "./terminStatus";
import { keyBy } from '../store/utils/lodash-replacement';

export const EINNAHME_BAR = 1
export const EINNAHME_ANZAHLUNG = 2
export const EINNAHME_GUTSCHEIN = 3
export const EINNAHME_UEBERWEISUNG = 4
export const EINNAHME_GESCHAEFTSKONTO = 5
export const EINNAHME_PRIVATKONTO = 6

export const EINNAHMENART_MAP = ['', 'EINNAHME_BAR', 'EINNAHME_ANZAHLUNG', 'EINNAHME_GUTSCHEIN'
    , 'EINNAHME_UEBERWEISUNG', 'EINNAHME_GESCHAEFTSKONTO', 'EINNAHME_PRIVATKONTO']

export const EINNAHMENART_SELECTION = [1, 2, 3, 4, 5, 6].map(id => ({id, text: EINNAHMENART_MAP[id]}))

export const getTranslatedEinnahmenartSelection = (t) => keyBy(EINNAHMENART_SELECTION.map(item => ({
    id: item.id, text: t(item.text, {ns: i18_EINNAHMEN})
})), 'id')

export const createTerminEinnahmenSchema = () => {
    return yup.object().shape({
        Anmerkung: yup.string().max(100).nullable(true),
        Betrag: yup.number().when(['EinnArt', '$maxBetrag'], ([einArt, maxBetrag], schema) => {
            return einArt === EINNAHME_GUTSCHEIN ? schema.max(maxBetrag || 0).required() : schema.required()
        }),
        VouchNo: yup.number().min(0).when(['EinnArt'], ([einnArt], schema) => {
            if (einnArt && +einnArt === EINNAHME_GUTSCHEIN) {
                return schema.required()
            }
            return schema.nullable(true)
        })
    })
}

export const convertEinnahmenForEdit = (einnahmen) => einnahmen.map(einnahme => convertSingleEinnahmeForEdit(einnahme))
export const convertSingleEinnahmeForEdit = (einnahme) => ({...einnahme, id: uuid()})
export const createEinnahme = (terminId) => ({Anmerkung: '', Betrag: 0, id: uuid(), TerminID: terminId, EinnArt: 1})
export const isEinnahmeGespeichert = einnId => einnId > 0
export const isAnzahlung = einnahme => einnahme.EinnArt === EINNAHME_ANZAHLUNG
export const isVoucherEinnahme = einnahme => !isEinnahmeGespeichert(einnahme?.EinnID) && einnahme.EinnArt === EINNAHME_GUTSCHEIN


const convertLeistung = (row, t, kautionsText, termintypMap) => {
    if (TERMINTYPEN_DEPOSIT.includes(row.Typ)) {
        return kautionsText
    }
    if (row.TrmStat === TERMINSTATUS_AUSGEFALLEN) {
        return t('EINNAHMEN_LIST.LEISTUNG_TERMINAUSFALL', {ns: i18_LIST})
    }
    return termintypMap[row.Typ].Termintyp
}

const convertZahlungsartSteuer = (value, kautionsText, einArtMap) => {
    if (value === EINNAHME_ANZAHLUNG) {
        return kautionsText
    }
    return einArtMap[value]?.text || value
}

const convertZahlungsartTerminkaution2Bar = (value, einArtMap) => {
    if (value === EINNAHME_ANZAHLUNG) {
        return einArtMap[EINNAHME_BAR]?.text || value
    }
    return einArtMap[value]?.text || value
}

export const getEinnahmenListColumns = ({
                                            t,
                                            artistMap,
                                            termintypMap,
                                            translatedTerminStatusMap,
                                            kautionsText,
                                            einArtMap,
                                            locale
                                        }) => {
    const valueFormatter = createValueFormatter(locale)
    return [
        {field: 'KundID', type: 'number', headerName: t('LIST.KUNDID_LABEL', {ns: i18_LIST})},
        {field: 'ProjektID', type: 'number', headerName: t('LIST.PROJEKTID_LABEL', {ns: i18_LIST})},
        {field: 'TerminID', type: 'number', headerName: t('LIST.TERMINID_LABEL', {ns: i18_LIST})},
        {field: 'EinnID', type: 'number', headerName: t('LIST.EINNID_LABEL', {ns: i18_LIST})},
        {field: 'Vorname', headerName: t('EDIT_KUNDE.VORNAME_LABEL', {ns: i18_KUNDE})},
        {field: 'Nachname', headerName: t('EDIT_KUNDE.NACHNAME_LABEL', {ns: i18_KUNDE})},
        {
            field: 'ArtistID',
            headerName: t('EINNAHMEN_LIST.ARTIST_HEADER', {ns: i18_LIST}),
            valueGetter: (value) => value && artistMap && artistMap[value].Kuerzel
        },
        {
            field: 'Typ',
            headerName: t('EINNAHMEN_LIST.TERMINTYP_HEADER', {ns: i18_LIST}),
            valueGetter: (value) => value !== undefined && termintypMap && termintypMap[value].Termintyp
        },
        {
            field: 'TrmStat',
            headerName: t('EINNAHMEN_LIST.TERMINSTATUS_HEADER', {ns: i18_LIST}),
            valueGetter: (value) => value !== undefined && translatedTerminStatusMap && translatedTerminStatusMap[value]
        },
        {
            field: 'Start',
            type: 'dateTime',
            headerName: t('EDIT_TERMIN.START_LABEL', {ns: i18_TRANSLATION}),
            valueGetter: (value) => value && new Date(value),
            minWidth: 150
        },
        {
            field: 'DatBis',
            type: 'dateTime',
            headerName: t('EDIT_TERMIN.END_LABEL', {ns: i18_TRANSLATION}),
            valueGetter: (value) => value && new Date(value),
            minWidth: 150
        },
        {field: 'Betreff', headerName: t('EDIT_TERMIN.BETREFF_LABEL', {ns: i18_EDITTERMIN}), minWidth: 150},
        {
            field: 'Leistung',
            headerName: t('EINNAHMEN_LIST.LEISTUNG_HEADER', {ns: i18_LIST}),
            minWidth: 150,
            valueGetter: (value, row) => convertLeistung(row, t, kautionsText, termintypMap)
        },
        {
            field: 'ZahlungsartStudio',
            headerName: t('EINNAHMEN_LIST.ZAHLUNGSART_STUDIO_HEADER', {ns: i18_LIST}),
            minWidth: 150,
            valueGetter: (value, row) => convertZahlungsartSteuer(row.EinnArt, kautionsText, einArtMap)
        },
        {
            field: 'Zahlungsart',
            headerName: t('EINNAHMEN_LIST.ZAHLUNGSART_HEADER', {ns: i18_LIST}),
            minWidth: 150,
            valueGetter: (value, row) => convertZahlungsartTerminkaution2Bar(row.EinnArt, einArtMap)
        },
        {
            field: 'Betrag',
            headerName: t('EINNAHMEN_LIST.BETRAG_HEADER', {ns: i18_LIST}),
            valueFormatter
        },
        {
            field: 'Anmerkung',
            headerName: t('EINNAHMEN_LIST.ANMERKUNG_HEADER', {ns: i18_LIST}),
            minWidth: 250,
        }
    ]
}

