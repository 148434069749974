import React from "react";
import {Grid2, Paper} from "@mui/material";

const StandardContainer = ({children}) => {
    return (
        (<React.Fragment>
            <Grid2 container justifyContent="center">
                <Grid2
                    style={{display: 'flex', flexDirection: 'column'}}
                    size={{
                        xs: 12,
                        sm: 8,
                        md: 6
                    }}>
                    <Paper style={{width: '100%', minHeight: '100vh', opacity: '0.95'}}>
                        {children}
                    </Paper>
                </Grid2>
            </Grid2>
        </React.Fragment>)
    );
}

export default StandardContainer