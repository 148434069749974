import { createSelector } from "reselect";

export const selectKundeState = createSelector(
    [(state) => state]
    , (state) => state.kunde
)

export const selectKundeData = createSelector([selectKundeState], state => state?.kundeData)
export const selectPageOfKundeData = createSelector([selectKundeState], state => state?.page ?? 'KUNDE')

export const selectKundenDoubletten = createSelector([selectKundeState], state => state?.doubletten ?? [])
export const selectIsKundeDoublettenLoaded = createSelector([selectKundeState], state => Boolean(state?.doublettenParams))
export const selectIsKundeDataLoading = createSelector([selectKundeState], state => Boolean(state?.terminDataLoading))
export const selectKunde = createSelector([selectKundeData], state => state?.kunde)
export const selectKundenBerufe = createSelector([selectKundeData], state => state?.berufe)
export const selectKundenProjekte = createSelector([selectKundeData], state => state?.projekte)
export const selectKundenAnzahlungen = createSelector([selectKundeData], state => state?.anzahlungen)
export const selectKundenNachrichten = createSelector([selectKundeData], state => state?.nachrichten)
export const selectKundenFiles = createSelector([selectKundeData], state => state?.files)
export const selectKundenUnfinishedFiles = createSelector([selectKundeData], state => state?.unfinishedFiles)
export const selectKundenTermine = createSelector([selectKundeData], state => state?.termine)
export const selectIsKundeDeletable = createSelector(
    [selectKundenProjekte
    , selectKundenUnfinishedFiles
    , selectKundenTermine]
    , (projekte, unfinishedFiles, termine) => !projekte?.length && !unfinishedFiles?.length && !termine?.length && !unfinishedFiles?.length
)
export const selectIsProjektMergeOngoing = createSelector([selectKundeState], state => Boolean(state?.mergingProjectsOngoing))
export const selectIsProjektDeleteOngoing = createSelector([selectKundeState], state => Boolean(state?.deleteProjectOngoing))
export const selectIsBatchProcessing = createSelector([selectKundeState], state => Boolean(state?.batchInProgress))
export const selectImportResult = createSelector([selectKundeState], state => state?.importResult)