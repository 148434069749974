import { DELETE_KUNDE, DELETE_KUNDEN_PROJEKT } from "../kunde/kundeAction";
import { DELETE_TERMIN } from "../termin/terminActions";
import { replacePayloadFields } from "../utils/reducerUtils";
import {
    CLEAR_DOUBLETTEN_MERGE_KUNDEN_DATA,
    CLEAR_JOB_DETAILS,
    CLEAR_LIST_DATA,
    LOAD_ALL_REPORT_DEFINITIONS,
    LOAD_ALL_REPORT_DEFINITIONS_FAILURE,
    LOAD_ALL_REPORT_DEFINITIONS_SUCCESS,
    LOAD_CHECKOUT_LIST_DATA,
    LOAD_CHECKOUT_LIST_DATA_FAILURE,
    LOAD_CHECKOUT_LIST_DATA_SUCCESS,
    LOAD_DBLEXCLUSION_LIST_DATA,
    LOAD_DBLEXCLUSION_LIST_DATA_FAILURE,
    LOAD_DBLEXCLUSION_LIST_DATA_SUCCESS,
    LOAD_DOUBLETTEN_LIST_DATA,
    LOAD_DOUBLETTEN_LIST_DATA_FAILURE,
    LOAD_DOUBLETTEN_LIST_DATA_SUCCESS,
    LOAD_DOUBLETTEN_MERGE_KUNDEN,
    LOAD_DOUBLETTEN_MERGE_KUNDEN_FAILURE,
    LOAD_DOUBLETTEN_MERGE_KUNDEN_SUCCESS,
    LOAD_EINNAHMEN_LIST_DATA,
    LOAD_EINNAHMEN_LIST_DATA_FAILURE,
    LOAD_EINNAHMEN_LIST_DATA_SUCCESS,
    LOAD_JOB_DETAILS,
    LOAD_JOB_DETAILS_SUCCESS,
    LOAD_KUNDEN_LIST_DATA,
    LOAD_KUNDEN_LIST_DATA_FAILURE,
    LOAD_KUNDEN_LIST_DATA_SUCCESS,
    LOAD_MANDANTEN_JOBS_DATA,
    LOAD_MANDANTEN_JOBS_FAILURE,
    LOAD_MANDANTEN_JOBS_SUCCESS,
    LOAD_NACHRICHTEN_LIST_DATA,
    LOAD_NACHRICHTEN_LIST_DATA_FAILURE,
    LOAD_NACHRICHTEN_LIST_DATA_SUCCESS,
    LOAD_PROJEKT_LIST_DATA,
    LOAD_PROJEKT_LIST_DATA_FAILURE,
    LOAD_PROJEKT_LIST_DATA_SUCCESS,
    LOAD_REPORT_DEFINITION,
    LOAD_REPORT_DEFINITION_FAILURE,
    LOAD_REPORT_DEFINITION_SUCCESS,
    LOAD_TERMIN_LIST_DATA,
    LOAD_TERMIN_LIST_DATA_FAILURE,
    LOAD_TERMIN_LIST_DATA_SUCCESS,
    LOAD_VOUCHIN_LIST_DATA,
    LOAD_VOUCHIN_LIST_DATA_FAILURE,
    LOAD_VOUCHIN_LIST_DATA_SUCCESS,
    SET_DATBIS_FOR_PROJEKT_LIST,
    SET_DATVON_FOR_PROJEKT_LIST,
    SET_PARAMETERS_DBLEXCLUSION_LIST,
    SET_PARAMETERS_EINNAHMEN_LIST,
    SET_PARAMETERS_JOBS_LIST,
    SET_PARAMETERS_KUNDEN_LIST,
    SET_PARAMETERS_NACHRICHTEN_LIST,
    SET_PARAMETERS_TERMIN_LIST,
    SET_PARAMETERS_VOUCHIN_LIST,
    SET_SELECTED_ARTISTID_LIST_PROJEKT_LIST,
    SET_SELECTED_LIST_ID_LIST,
    SET_SELECTED_STATUS_LIST_PROJEKT_LIST,
    SET_WITHOUT_APPOINTMENT_FLAG_PROJEKT_LIST
} from "./listActions";

const INITIAL = {selectedListIdList: []}

const ALL_LIST_DATA_CLEARED = {
    kundenListData: undefined,
    terminListData: undefined,
    projektListData: undefined,
    einnahmenListData: undefined,
    mandantenJobsListData: undefined,
    checkoutListData: undefined,
    vouchersListData: undefined,
    vouchInListData: undefined,
    dblExclusionListData: undefined,
    doublettenListData: undefined,
    mergeKundenData: undefined,
    selectedListIdList: []
}

export default function listReducers(state = INITIAL, action) {
    switch (action.type) {
        case LOAD_KUNDEN_LIST_DATA:
            return {
                ...state,
                listDataLoading: true,
                ...ALL_LIST_DATA_CLEARED
            }

        case LOAD_PROJEKT_LIST_DATA:
            return {
                ...state,
                listDataLoading: true,
                ...ALL_LIST_DATA_CLEARED,
                projektParameters: action.payload.parameters
            }

        case LOAD_TERMIN_LIST_DATA:
            return {
                ...state,
                listDataLoading: true,
                ...ALL_LIST_DATA_CLEARED,
                terminParameters: action.payload.parameters
            }

        case LOAD_EINNAHMEN_LIST_DATA:
            return {
                ...state,
                listDataLoading: true,
                ...ALL_LIST_DATA_CLEARED,
                einnahmenParameters: action.payload.parameters
            }

        case LOAD_MANDANTEN_JOBS_DATA:
            return {
                ...state,
                listDataLoading: true,
                ...ALL_LIST_DATA_CLEARED,
                jobsParameters: action.payload.parameters
            }
        case LOAD_CHECKOUT_LIST_DATA:
            return {
                ...state,
                listDataLoading: true,
                ...ALL_LIST_DATA_CLEARED,
                terminParameters: action.payload.parameters
            }

        case LOAD_VOUCHIN_LIST_DATA:
            return {
                ...state,
                listDataLoading: true,
                ...ALL_LIST_DATA_CLEARED,
                vouchInParameters: action.payload.parameters
            }

        case LOAD_DBLEXCLUSION_LIST_DATA:
            return {
                ...state,
                listDataLoading: true,
                ...ALL_LIST_DATA_CLEARED,
                dblExclusionParameters: action.payload.parameters
            }

        case LOAD_DOUBLETTEN_LIST_DATA:
            return {
                ...state,
                listDataLoading: true,
                ...ALL_LIST_DATA_CLEARED
            }

        case LOAD_DOUBLETTEN_MERGE_KUNDEN:
            return {
                ...state,
                listDataLoading: true,
                mergeKundenData: undefined
            }

        case LOAD_NACHRICHTEN_LIST_DATA:
            return {
                ...state,
                listDataLoading: true,
                nachrichtenListData: undefined
            }

        case LOAD_KUNDEN_LIST_DATA_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                kundenListData: action.payload.kundenListData
            }

        case DELETE_KUNDE:
            return {
                ...state,
                kundenListData: undefined
            }

        case LOAD_PROJEKT_LIST_DATA_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                projektListData: action.payload.projektListData
            }
        case DELETE_KUNDEN_PROJEKT:
            return {
                ...state,
                projektListData: undefined
            }
        case LOAD_TERMIN_LIST_DATA_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                terminListData: action.payload.terminListData
            }
        case DELETE_TERMIN:
            return {
                ...state,
                terminListData: undefined
            }
        case LOAD_EINNAHMEN_LIST_DATA_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                einnahmenListData: action.payload.einnahmenListData
            }
        case LOAD_MANDANTEN_JOBS_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                mandantenJobsListData: action.payload.mandantenJobsListData,
                jobDetails: undefined,
            }
        case LOAD_CHECKOUT_LIST_DATA_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                checkoutListData: action.payload.checkoutListData
            }

        case LOAD_VOUCHIN_LIST_DATA_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                vouchInListData: action.payload.vouchInListData
            }

        case LOAD_DBLEXCLUSION_LIST_DATA_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                dblExclusionListData: action.payload.dblExclusionListData
            }

        case LOAD_DOUBLETTEN_LIST_DATA_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                doublettenListData: action.payload.doublettenListData
            }
        case LOAD_DOUBLETTEN_MERGE_KUNDEN_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                mergeKundenData: action.payload.mergeKundenData
            }
        case LOAD_NACHRICHTEN_LIST_DATA_SUCCESS:
            return {
                ...state,
                listDataLoading: false,
                nachrichtenListData: action.payload.nachrichtenListData
            }

        case LOAD_KUNDEN_LIST_DATA_FAILURE:
        case LOAD_PROJEKT_LIST_DATA_FAILURE:
        case LOAD_TERMIN_LIST_DATA_FAILURE:
        case LOAD_EINNAHMEN_LIST_DATA_FAILURE:
        case LOAD_MANDANTEN_JOBS_FAILURE:
        case LOAD_CHECKOUT_LIST_DATA_FAILURE:
        case LOAD_VOUCHIN_LIST_DATA_FAILURE:
        case LOAD_DBLEXCLUSION_LIST_DATA_FAILURE:
        case LOAD_DOUBLETTEN_LIST_DATA_FAILURE:
        case LOAD_DOUBLETTEN_MERGE_KUNDEN_FAILURE:
        case LOAD_NACHRICHTEN_LIST_DATA_FAILURE:
            return {
                ...state,
                listDataLoading: false
            }

        case LOAD_REPORT_DEFINITION:
            return {
                ...state,
                listReportDefinitionLoading: true,
                reportDefinition: undefined
            }
        case LOAD_REPORT_DEFINITION_SUCCESS:
            return {
                ...state,
                listReportDefinitionLoading: false,
                reportDefinition: action.payload.reportDefinition
            }
        case LOAD_REPORT_DEFINITION_FAILURE:
            return {
                ...state,
                listReportDefinitionLoading: false
            }

        case LOAD_ALL_REPORT_DEFINITIONS:
            return {
                ...state,
                allReportDefinitionsLoading: true,
                allReportDefinitions: undefined
            }
        case LOAD_ALL_REPORT_DEFINITIONS_SUCCESS:
            return {
                ...state,
                allReportDefinitionsLoading: false,
                allReportDefinitions: action.payload.allReportDefinitions
            }
        case LOAD_ALL_REPORT_DEFINITIONS_FAILURE:
            return {
                ...state,
                allReportDefinitionsLoading: false
            }

        case LOAD_JOB_DETAILS:
            return {
                ...state
                , jobDetails: undefined
                , loadingJobDetails: true
            }

        case LOAD_JOB_DETAILS_SUCCESS:
            return {
                ...state
                , jobDetails: action.payload.jobDetails
                , loadingJobDetails: false
            }

        case CLEAR_JOB_DETAILS:
            return {
                ...state,
                jobDetails: undefined,
                loadingJobDetails: undefined
            }

        case CLEAR_DOUBLETTEN_MERGE_KUNDEN_DATA:
            return {
                ...state,
                mergeKundenData: undefined
            }

        case SET_PARAMETERS_KUNDEN_LIST:
        case SET_PARAMETERS_TERMIN_LIST:
        case SET_PARAMETERS_EINNAHMEN_LIST:
        case SET_PARAMETERS_JOBS_LIST:
        case SET_PARAMETERS_VOUCHIN_LIST:
        case SET_PARAMETERS_DBLEXCLUSION_LIST:
        case SET_SELECTED_ARTISTID_LIST_PROJEKT_LIST:
        case SET_DATVON_FOR_PROJEKT_LIST:
        case SET_DATBIS_FOR_PROJEKT_LIST:
        case SET_SELECTED_STATUS_LIST_PROJEKT_LIST:
        case SET_WITHOUT_APPOINTMENT_FLAG_PROJEKT_LIST:
        case SET_PARAMETERS_NACHRICHTEN_LIST:
        case SET_SELECTED_LIST_ID_LIST:
            return replacePayloadFields(state, action)

        case CLEAR_LIST_DATA:
            return INITIAL

        default:
            return state
    }
}