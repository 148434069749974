import { createSelector } from "reselect";
import {
    convertMsgVorlagenMapToList,
    MSG_VORLAGE_TYP_AUTO_REMINDER,
} from "../../models/vorlagen";
import { getCountryByPreDial } from "@kiss-solutions/countries/lib/countries";
import { termintypSort } from "../../models/terminStatus";
import { keyBy } from "../utils/lodash-replacement";

export const selectCommonState = createSelector([(state) => state], (state) => state.common);


export const selectDisabledInfo = createSelector([selectCommonState], (state) => state?.disabledInfo);
export const selectCountryMap = createSelector([selectCommonState], (state) => state?.countryMap);
export const selectTermintypMap = createSelector([selectCommonState], (state) => state?.termintypMap);
export const selectTermintypList = createSelector([selectTermintypMap], (map) => map && Object.values(map));
export const selectMsgVorlagenMap = createSelector([selectCommonState], (state) => state?.msgVorlagenMap);
export const selectMsgVorlagenList = createSelector([selectMsgVorlagenMap], (map) => convertMsgVorlagenMapToList(map));

export const selectSubscriptionStatus = createSelector([selectCommonState], (state) => state?.subscriptionStatus);
export const selectStudioData = createSelector([selectCommonState], (state) => state?.studio);
export const selectStudioTatOrtID = createSelector([selectStudioData], (state) => state?.TatOrtID);
export const selectStudioTimezone = createSelector([selectStudioData], (state) => state?.Timezone);
export const selectStudioPads = createSelector([selectStudioData], (state) => state?.pads || []);
export const selectStudioPadMap = createSelector([selectStudioPads], (pads) => keyBy(pads, "PadID"));
export const selectStudioGutscheinverkauf = createSelector([selectStudioData], (state) => state?.Gutscheinverkauf);
export const selectStudioWaehrung = createSelector([selectStudioData], (state) => state?.Waehrung);
export const selectStudioTatortMap = createSelector(
    [selectStudioData],
    (studio) => studio && keyBy(studio.tatorte, "TatOrtID")
);

export const selectStudioCountry = createSelector([selectStudioData], (studio) =>
    studio?.preDial ? getCountryByPreDial(studio.preDial) : getCountryByPreDial("+49")
);

export const selectMainBranchLocation = createSelector(
    [selectStudioTatortMap, selectStudioTatOrtID],
    (map, tatortId) => map && tatortId && map[tatortId]
);
export const selectCountrySelection = createSelector(
    [selectCountryMap],
    (state) => (state && Object.values(state).map((t) => ({ id: t.country_code, text: t.name }))) || []
);


export const selectIsCommonStateLoading = createSelector([selectCommonState], (state) => state.commonLoading || false);
export const selectArtistMap = createSelector([selectCommonState], (state) => state.artistMap);

export const selectTerminReminderMsgVorlage = createSelector(
    [selectMsgVorlagenMap],
    (vorlagenMap) => vorlagenMap && Object.values(vorlagenMap).find((t) => t.MsgTyp === MSG_VORLAGE_TYP_AUTO_REMINDER)
);
export const selectTerminReminderVorlauf = createSelector(
    [selectTerminReminderMsgVorlage],
    (vorlage) => vorlage && vorlage.DaysOffset
);
export const selectTermintypSelection = createSelector(
    [selectTermintypMap],
    (termintypMap) =>
        (termintypMap &&
            Object.values(termintypMap)
                .sort(termintypSort)
                .map((t) => ({ ...t, id: t.TermintypID, text: t.Termintyp }))) ||
        []
);

export const selectHasStudioChanged = createSelector([selectCommonState], (state) => Boolean(state?.studioChanged));

export const selectSupportTicketStatus = createSelector([selectCommonState], (state) => state?.supportTicketStatus);
