import * as yup from "yup";
import {i18_TRANSLATION} from "../i18nReferences";

export const TYPE_TO_TRANSLATION_KEY = {
    kunde: 'LIST.LIST_TYP_KUNDE',
    dblexclusions: 'LIST.LIST_TYP_DBLEXCLUSION',
    termin: 'LIST.LIST_TYP_TERMIN',
    projekt: 'LIST.LIST_TYP_PROJEKT',
    jobs: 'LIST.LIST_TYP_JOBS',
    checkout: 'LIST.LIST_TYP_CHECKOUT',
    vouchers: 'LIST.LIST_TYP_VOUCHERS',
    vouchin: 'LIST.LIST_TYP_VOUCHIN',
    einnahmen: 'LIST.LIST_TYP_EINNAHMEN',
    doubletten: 'LIST.LIST_TYP_DOUBLETTEN',
    nachrichten: 'LIST.LIST_TYP_NACHRICHTEN'
}

export const translateListTyp = (value, t) => t(TYPE_TO_TRANSLATION_KEY[value])

export const getListColumnDefinitions = (t, artistMap) => [
    {field: 'Category', headerName: t('LIST.LIST_CATEGORY_HEADER'), minWidth: 150},
    {
        field: 'Typ',
        headerName: t('LIST.LIST_TYPE_HEADER'),
        valueGetter: (value) => value && translateListTyp(value, t),
        minWidth: 150
    },
    {field: 'isAdmin', type: 'boolean', headerName: t('LIST.ADMIN_ONLY_HEADER')},
    {field: 'Bezeichnung', headerName: t('LIST.LIST_NAME_HEADER'), flex: 1},
    {
        field: '_createdID',
        headerName: t('CREATED_KUERZEL_LABEL', {ns: i18_TRANSLATION}),
        valueGetter: (value) => value && artistMap[value].Kuerzel
    },
    {
        field: '_createdTS',
        type: 'dateTime',
        headerName: t('CREATED_TIME_LABEL', {ns: i18_TRANSLATION}),
        valueGetter: (value) => value && new Date(value),
        minWidth: 150
    }
]

const ADD_LIST_HIDE_COLUMNS = ['_createdTS', '_createdID']
const TREE_LIST_HIDE_COLUMNS = ['Category', 'Bezeichnung']

export const getSystemListColumnDefinitions = (t) => getListColumnDefinitions(t).filter(l => !ADD_LIST_HIDE_COLUMNS.includes(l.field))
export const getTreeColumnDefinitions = (t, artistMap) => getListColumnDefinitions(t, artistMap).filter(l => !TREE_LIST_HIDE_COLUMNS.includes(l.field))

export const createListDefinitionSchema = () => {
    return yup.object().shape({
        Typ: yup.string().required(),
        Category: yup.string().max(150).required(),
        Bemerkung: yup.string().nullable(),
        Bezeichnung: yup.string().max(200).required(),
    })
}

export const convertReportDefinitionSchemaToDb = (reportDefinition) => {
    const Definition = reportDefinition.Definition ?
        (typeof reportDefinition.Definition === 'string' ? reportDefinition.Definition : JSON.stringify(reportDefinition.Definition))
        : null
    const FetchFilter = reportDefinition.FetchFilter ?
        (typeof reportDefinition.FetchFilter === 'string' ? reportDefinition.FetchFilter : JSON.stringify(reportDefinition.FetchFilter))
        : null
    return {
        ...reportDefinition
        , _createdTS: reportDefinition._createdTS && new Date(reportDefinition._createdTS).toISOString()
        , Definition
        , FetchFilter
    }
}

export const convertReportDefinitionToSchema = (reportDefinition) => ({
    ...reportDefinition
    , _createdTS: reportDefinition._createdTS && new Date(reportDefinition._createdTS)
    , Definition: reportDefinition.Definition && JSON.parse(reportDefinition.Definition) || {}
    , FetchFilter: reportDefinition.FetchFilter && JSON.parse(reportDefinition.FetchFilter) || {}
})

export const sortByCategoryAndName = (reportDef1, reportDef2) =>
    (reportDef1?.Category || '') + (reportDef1?.Bezeichnung || '') > (reportDef2?.Category || '') + (reportDef2?.Bezeichnung || '') ? 1 : -1


const MINUS_DIRECTION = 'MINUS'
const PLUS_DIRECTION = 'PLUS'
export const LIST_YEAR_UNIT = 'YEAR'
export const LIST_DAY_UNIT = 'DAY'

export const constructRelativeDateToActual = (diff = 0, unit = LIST_DAY_UNIT) => `ACT_DATE-${diff < 0 ? MINUS_DIRECTION : PLUS_DIRECTION}-${Math.abs(diff).toString()}-${unit}`

export const getStartOfActDay = () => {
    const actDate = new Date()
    actDate.setHours(0, 0, 0, 0)
    return actDate
}

export const evaluateRelativeDateToActual = (relativeDateString, actDate = getStartOfActDay()) => {
    if (!relativeDateString || !relativeDateString.startsWith('ACT_DATE')) {
        return undefined
    }
    const [, direction, count, timeUnit] = relativeDateString.split('-')
    const timeDiff = +count * (direction === MINUS_DIRECTION ? -1 : +1)
    if (timeUnit === LIST_YEAR_UNIT) {
        actDate.setFullYear(actDate.getFullYear() + timeDiff)
    } else {
        actDate.setDate(actDate.getDate() + timeDiff)
    }
    return actDate
}

export const numberFormatter = (number, minimumFractionDigits = 2, locale = 'de') => number != null ?
    (typeof number === 'string' ? Number(number).toLocaleString(locale, {minimumFractionDigits})
        : number.toLocaleString(locale, {minimumFractionDigits})) : ''


export const createValueFormatter = (locale, minimumFractionDigits) => (value) => numberFormatter(value, minimumFractionDigits, locale)
