import axios from "axios";
import {BACKEND} from "../config/backend";

const TIMEOUT = 5000

export const createAuthHeader = token => token ? ({headers: {
        'Authorization': `Bearer ${token}`
    }, timeout: TIMEOUT}) : ({timeout: TIMEOUT})

export const createMultipartFormHeader = token => ({headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }, timeout: 30000})

export const addAuthHeader = (token, header) => token ? ({headers: {...header,
        'Authorization': `Bearer ${token}`
    }, timeout: TIMEOUT}) : ({timeout: TIMEOUT})

export const getArtistRefreshTokenEndpoint = refreshToken => `${BACKEND}/api/v1/artists/loginArtist/refresh?token=${encodeURIComponent(refreshToken)}`

export const getArtistRefreshToken = ({refreshToken, token}) => {
    return axios.get(getArtistRefreshTokenEndpoint(refreshToken), {
        ...createAuthHeader(token)
    })
}

export const getStudioRefreshTokenEndpoint = refreshToken => `${BACKEND}/api/v1/users/loginStudio/refresh?token=${encodeURIComponent(refreshToken)}`

export const getStudioRefreshToken = ({refreshToken}) => {
    return axios.get(getStudioRefreshTokenEndpoint(refreshToken))
}

export const getCountryMap = () => {
    return axios.get(`${BACKEND}/api/v1/countries`)
}

export const getStudio = ({token}) => {
    return axios.get(`${BACKEND}/api/v1/studio`, {
        ...createAuthHeader(token)
    })
}

export const updateStudio = ({token, studio}) => {
    return axios.put(`${BACKEND}/api/v1/studio`, studio, {
        ...createAuthHeader(token)
    })
}

export const getTermintypen = ({token}) => {
    return axios.get(`${BACKEND}/api/v1/studio/termintypen`, {
        ...createAuthHeader(token)
    })
}

export const getMessageVorlagen = ({token}) => {
    return axios.get(`${BACKEND}/api/v1/studio/msgvorlagen`, {
        ...createAuthHeader(token)
    })
}

export const updateMessageVorlage = ({token, msgVorlage}) => {
    return axios.put(`${BACKEND}/api/v1/studio/msgvorlagen`, msgVorlage, {
        ...createAuthHeader(token)
    })
}

export const updateAutoMsgSettings = ({token, autoMsgSettings}) => {
    return axios.put(`${BACKEND}/api/v1/studio/msgauto`, autoMsgSettings, {
        ...createAuthHeader(token)
    })
}

export const insertMessageVorlage = ({token, msgVorlage}) => {
    return axios.post(`${BACKEND}/api/v1/studio/msgvorlagen`, msgVorlage, {
        ...createAuthHeader(token)
    })
}

export const deleteMessageVorlage = ({token, msgVorlageId}) => {
    return axios.delete(`${BACKEND}/api/v1/studio/msgvorlagen/${msgVorlageId}`, {
        ...createAuthHeader(token)
    })
}

export const loginStudio = ({studio, password}) => {
    return axios.post(`${BACKEND}/api/v1/users/loginStudio`, {studio, password})
}

export const loginArtist = ({token, kuerzel, password}) => {
    return axios.post(`${BACKEND}/api/v1/artists/loginArtist`, {kuerzel, password}, {
        ...createAuthHeader(token)
    })
}

export const initiateStudioLoginReset = ({studio}) => {
    return axios.post(`${BACKEND}/api/v1/users/studio/password/reset`, {studio})
}





