import { createSelector } from "reselect";
import { convertImagesRaw2ProjektImages } from "../../models/projekt";

export const selectProjektState = createSelector(
    [(state) => state]
    , (state) => state.projekt
)

export const selectProjekt = createSelector([selectProjektState], state => state?.projekt)
export const selectProjektImagesRaw = createSelector([selectProjektState], state => state?.projektImages ?? [])
export const selectProjektImagesBase = createSelector(
    [selectProjektImagesRaw]
    , (projektImages) => convertImagesRaw2ProjektImages(projektImages)
)
export const selectInsertedTempImages = createSelector([selectProjektState], state => state?.insertedImages ?? [])
export const selectDeletedProjektImages = createSelector([selectProjektState], state => state?.deletedImages ?? [])
export const selectDeletedProjektImagekeys = createSelector(
    [selectDeletedProjektImages]
    , deletedImages => deletedImages?.length > 0 ? deletedImages.map(t => t.key) : []
)

export const selectProjektImages = createSelector(
    [selectProjektImagesBase
    , selectDeletedProjektImagekeys
    , selectInsertedTempImages]
    , (projektImages, deletedKeys, insertedImages) => projektImages.filter(t => !deletedKeys.includes(t.key))
        .concat(insertedImages.map(key =>({type: 'temp', key})))
)
export const selectIsProjektUpdating = createSelector([selectProjektState], state => Boolean(state?.projektUpdatePending))
export const selectIsUploadingProjektImages = createSelector([selectProjektState], state => Boolean(state?.isUploadingImages))