import { all } from 'redux-saga/effects';
import artistSaga from "./artist/artistSaga";
import authSaga from "./auth/authSaga";
import calendarSagas from "./calendar/calendarSaga";
import commonSagas from "./common/commonSaga";
import finalizerSaga from "./finalizer/finalizerSaga";
import kundeSaga from "./kunde/kundeSaga";
import kundenTermineSaga from "./kundenTermine/kundenTermineSaga";
import listsSaga from "./list/listSaga";
import manageProjekteSaga from "./manage/projekte/manageProjekteSaga";
import nachrichtSaga from "./nachricht/nachrichtSaga";
import projektSaga from "./projekt/projektSaga";
import kissSignSaga from "./settings/kiss-sign/kissSignSaga";
import templateSagas from "./templates/templateSagas";
import terminSagas from "./termin/terminSagas";
import timelineSagas from "./timeline/timelineSaga";

const sagas = function* rootSaga() {
    yield all([...commonSagas,
        ...authSaga
        , ...terminSagas
        , ...kundeSaga
        , ...kundenTermineSaga
        , ...calendarSagas
        , ...finalizerSaga
        , ...manageProjekteSaga
        , ...nachrichtSaga
        , ...kissSignSaga
        , ...artistSaga
        , ...listsSaga
        , ...projektSaga
        , ...templateSagas
        , ...timelineSagas
    ]);
}

export default sagas;
