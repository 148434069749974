import React, { useEffect, useMemo, useRef } from "react";
import { Box, Button, Chip, Grid2, IconButton, Slider } from "@mui/material";
import TimelineTatortSelection from "./TimelineTatortSelection";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PlainDate } from "@kiss-solutions/plaindate";
import { i18_TRANSLATION } from "../../i18nReferences";
import { useTranslation } from "react-i18next";
import { debounce } from "../../store/utils/lodash-replacement";

const TIME_PERIOD_SLIDER_MARKS = [
    { value: 0, label: "00:00" },
    { value: 48, label: "12:00" },
    { value: 96, label: "24:00" },
];

function valueText(value) {
    const hour = Math.floor(value / 4);
    const minute = (value % 4) * 15;
    return `${hour.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`;
}

const MIN_TIME_SLIDER_DISTANCE = 4;

const TimelineSelection = ({
    tatortMap,
    tatortId,
    onChangeTatortId,
    start,
    onChangeStart,
    onChangeTimes,
    onClickToday,
}) => {
    const { t } = useTranslation([i18_TRANSLATION]);
    const [value, setValue] = React.useState([32, 80]);
    const isFirstRender = useRef(true);

    const debouncedPropagateValue = debounce(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        const times = value.map((v) => valueText(v));
        onChangeTimes(times);
    }, 500);

    useEffect(() => {
        debouncedPropagateValue();
        return () => {
            debouncedPropagateValue.cancel();
        };
    }, [debouncedPropagateValue, value]);

    const handleChange = (event, newValue, activeThumb) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value[1] - MIN_TIME_SLIDER_DISTANCE), value[1]]);
        } else {
            setValue([value[0], Math.max(newValue[1], value[0] + MIN_TIME_SLIDER_DISTANCE)]);
        }
    };
    const locationSelection = useMemo(
        () =>
            (tatortMap &&
                Object.values(tatortMap)
                    .filter((l) => l.Bookable || l.IstStudioEigen)
                    .map((l) => ({ id: l.TatOrtID, text: l.Ort }))) ||
            [],
        [tatortMap]
    );
    return (
        (<Grid2 container spacing={2} mt={2}>
            <Grid2 size={4}>
                <TimelineTatortSelection
                    onChange={onChangeTatortId}
                    locationSelection={locationSelection}
                    selectedTatortId={tatortId}
                />
            </Grid2>
            <Grid2 display="flex" alignItems="center" size={4}>
                <IconButton onClick={() => onChangeStart(new PlainDate(start).add(-7))}>
                    <ArrowBackIosIcon />
                </IconButton>
                <Box flex="0 0 auto" padding="0" sx={{ textAlign: "center" }}>
                    {start.toDateString()}
                </Box>
                <IconButton onClick={() => onChangeStart(new PlainDate(start).add(7))}>
                    <ArrowForwardIosIcon />
                </IconButton>
                <Button onClick={onClickToday} ml={2}>
                    {t("CALENDAR.HEUTE")}
                </Button>
            </Grid2>
            <Grid2 size={4}>
                <Box width="100%" display="flex" gap={4}>
                    <Chip label={valueText(value[0])} color="primary" />
                    <Box flex={1}>
                        <Slider
                            value={value}
                            min={0}
                            max={96}
                            onChange={handleChange}
                            marks={TIME_PERIOD_SLIDER_MARKS}
                            getAriaLabel={() => "Time Period"}
                        />
                    </Box>
                    <Chip label={valueText(value[1])} color="primary" />
                </Box>
            </Grid2>
        </Grid2>)
    );
};

export default TimelineSelection;
